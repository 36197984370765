import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../shared/components/Loader';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';


const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
         background-color: #F59300 !important;
    }
   
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListCSVIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListCSVIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {      
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
       
    }
    
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'fileName', numeric: false, label: 'File Name' },
    { id: 'comments', numeric: false, label: 'Comments' },
    { id: 'fileType', numeric: false, label: 'File Type' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function Files() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [message, setMessage] = React.useState(" ");
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    var [disable, setDisable] = React.useState(true);
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    const navigate = useNavigate();
    var [userEmail, setUserEmail] = React.useState("");
    var [deletePopup, setDeletePopup] = React.useState(false);
    const [deleteMessage, setDeleteMessage] = React.useState(" ");
    var [userProfile, setUserProfile] = React.useState();
    const [loading, setLoading] = React.useState(false);
    var [userId, setUserId] = React.useState();
    var [fileId, setFileId] = React.useState();
    const { userid } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    var [userEmail, setUserEmail] = React.useState("");

    useEffect(() => {

        userProfile = localStorage.getItem("loginRes");
        userProfile = JSON.parse(userProfile)
        setUserProfile(userProfile)
        if (userProfile?.userType == "User" || userProfile?.userType == "Leader") {
            userId = userProfile.id;
            setUserId(userId);
        } else {
            userId = userid
            setUserId(userId)
        }
        userEmail = queryParams.get('useremail');
        setUserEmail(userEmail)
        getFiles()

    }, [])

    const getFiles = () => {
        setLoading(true);
        setTimeout(() => {
            const RAFTCO_USER_URL = config.API_URL + "/api/user/file/list/" + userId
            axios.get(RAFTCO_USER_URL,
                {
                    headers: {
                        'Authorization': `Basic ${tokens.jwtToken}`
                    }
                }
            )
                .then((res) => {
                    if (res.data.successCode === 1) {
                        rows = res.data.data
                        setRows(rows);
                        filteredRows = rows;
                        setFilteredRows(filteredRows)
                        setDisable(false)
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                }).finally(() => {
                    setLoading(false);
                })
        })
    }


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const deleteFile = () => {

        const RAFTCO_DELETE_URL = config.API_URL + "/api/user/file/delete/" + fileId
        axios.get(RAFTCO_DELETE_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    getFiles()
                    setDeleteMessage(
                        <div className="message">
                            <div className="alert alert-success" role="alert">
                                "File deleted Successfully"
                            </div>
                        </div>)
                } else {
                    setDeleteMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                }
                setTimeout(() => { setDeletePopup(false) }, 3000)
                setTimeout(() => { setDeleteMessage("") }, 5000)
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })

    }

    const openDeletePopup = (row) => {
        fileId = row.fileRecordId
        setFileId(fileId)
        deletePopup = true;
        setDeletePopup(deletePopup);
    }

    const downloadFile = (row) => {

        const RAFTCO_URL = config.API_URL + "/api/user/file/download/" + row.fileRecordId
        axios.get(RAFTCO_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {

                    const data = res.data.data;
                    const element = document.createElement("a");
                    element.href = data.fileData
                    element.download = data.fileName
                    document.body.appendChild(element);
                    element.click();

                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }


    const handleDelete = (filteredRows) => {
        return (
            <div className="actionListDeleteIconPosition">
                <Tooltip title="delete" aria-label="delete">
                    <IconButton className="actionBtn" aria-label="delete" onClick={() => { openDeletePopup(filteredRows) }}>
                        <DeleteIcon className="actionIcon" />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    const handleDownload = (filteredRows) => {
        return (
            <div className="actionListCSVIconPosition">
                <Tooltip title="Download" aria-label="Download">
                    <IconButton className="actionBtn" aria-label="Download" onClick={() => { downloadFile(filteredRows) }}>
                        <GetAppIcon className="actionIcon" />
                    </IconButton>
                </Tooltip>
            </div>
        )
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    return (

        <TableWrapper>
            {message}
            <div className={`${classes.root} materialTableStyle`}>
                <div className="filterRow">
                    <div className="text-right">
                        <Button variant="contained" color="primary" className="noShadow addBtn" style={{ "marginTop": "15px", width: "150px" }} onClick={() => navigate("/user/file/upload?useremail=" + userEmail)}>
                            Add
                        </Button>
                    </div>
                </div>
                <TableContainer>
                    <Dialog open={deletePopup} closeonescape="true" maxWidth="true" >
                        <TableWrapper>
                            <DialogContent className="dialogStyle">
                                <div className={`${classes.root} materialTableStyle`}>
                                    Do you want to delete the record?
                                </div>
                                <DialogActions>
                                    <Grid container spacing={4} className="mt-1">
                                        <Grid item xs={12} sm={12} md={12} className="text-center">
                                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setDeletePopup(false)}>
                                                No
                                            </Button>&nbsp;&nbsp;
                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => deleteFile()}>
                                                Yes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </DialogContent>
                        </TableWrapper>
                        {deleteMessage}
                    </Dialog>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>
                            {stableSort(filteredRows, getComparator(order, orderBy))
                                .map((filteredRows, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={filteredRows.fileRecordId}
                                        >
                                            <TableCell align="left">{filteredRows.fileName}</TableCell>
                                            <TableCell align="left">{filteredRows.comments}</TableCell>
                                            <TableCell>
                                                {filteredRows.fileType}
                                                {handleDelete(filteredRows)}
                                                {handleDownload(filteredRows)}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </TableWrapper>
    );
}


