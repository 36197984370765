import React, { Component } from 'react';
import Scheduler, { SchedulerData, ViewTypes } from 'react-big-scheduler';
import 'react-big-scheduler/lib/css/style.css';
import withDragDropContext from './withDnDContext';
import config from '../config.json';
import axios from 'axios';
import moment from 'moment-timezone';
import './Schedule.scss'
import { Autocomplete, TextField, Button } from '@mui/material';
import { FilterInput } from '../../shared/components/FilterInput';
import { useNavigate } from 'react-router-dom';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday as the first day of the week
  },
});

class Basic extends Component {
  constructor(props) {
    super(props);

    const startDate = moment.tz(new Date(), this.timezone).format('YYYY-MM-DD');
    const endDate = moment.tz(new Date(), this.timezone).format('YYYY-MM-DD');

    this.state = {
      timezone: 'America/Toronto',
      tokens: null,
      message: " ",
      users: [],
      filteredUsers: [],
      trip: [],
      filteredTrip: [],
      startDate: startDate,
      endDate: endDate,
      viewModel: new SchedulerData(startDate, ViewTypes.Week, false, false,
        {
          checkConflict: true,
          eventItemPopoverEnabled: true,
          resourceName: 'Users',
          nonAgendaDayCellHeaderFormat: 'HH:mm',
          nonAgendaOtherCellHeaderFormat: 'ddd|M/D',
          nonWorkingTimeBodyBgColor: 'transparent',
          nonWorkingTimeHeadBgColor: 'transparent',
          nonWorkingTimeHeadColor: 'black',
          dayStartFrom: 6,
          dayStopTo: 22,


          views: [
            // {
            //   viewName: 'Day',
            //   viewType: ViewTypes.Day,
            //   showAgenda: false,
            //   isEventPerspective: false,
            // },
            {
              viewName: 'Week',
              viewType: ViewTypes.Week,
              showAgenda: false,
              isEventPerspective: false,
            }
          ],

        }),
      selectedUser: null,

    };


    this.getUsers = this.getUsers.bind(this);
    this.getSchedules = this.getSchedules.bind(this);
    this.onViewChange = this.onViewChange.bind(this);
    this.onSelectDate = this.onSelectDate.bind(this);
    this.eventClicked = this.eventClicked.bind(this);
    this.onScrollRight = this.onScrollRight.bind(this);
    this.onScrollLeft = this.onScrollLeft.bind(this);
    this.onScrollTop = this.onScrollTop.bind(this);
    this.onScrollBottom = this.onScrollBottom.bind(this);
    this.toggleExpandFunc = this.toggleExpandFunc.bind(this);
  }

  componentDidMount() {
    let token = localStorage.getItem("loginRes");
    token = JSON.parse(token);
    this.setState({ tokens: token }, () => {
      this.getUsers();
    });
    this.getWeekStartAndEndDates();

  }

  getUsers() {

    const RAFTCO_USER_URL = config.API_URL + "/api/Users/schedule";
    axios.get(RAFTCO_USER_URL, {
      headers: {
        'Authorization': `Basic ${this.state.tokens.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 1) {
          const users = res.data.data;
          const filteredUsers = res.data.data;
          this.setState({ users, filteredUsers }, () => {
            this.getSchedules();
          });
        } else {
          this.setState({
            message: (
              <div className="message">
                <div className="alert alert-danger" role="alert">
                  "An error occurred, try again later"
                </div>
              </div>
            )
          });
          setTimeout(() => { this.setState({ message: "" }) }, 5000);
        }
      })
      .catch(err => {
        console.log(err);
        // if (err.response.status === 401) {
        this.props.navigate("/login");
        // }
      });
  }

  getSchedules() {

    let userId = this.state.tokens?.userType === "User" ? this.state.tokens?.userId : -1;
    const { startDate, endDate, viewModel, users } = this.state;

    const RAFTCO_TRIP_SCHEDULE = config.API_URL + "/Schedule/api/Schedule/search";
    const data = {
      userId: userId,
      startTime: startDate,
      endTime: endDate
    };

    axios.post(RAFTCO_TRIP_SCHEDULE, data, {
      headers: {
        'Authorization': `Basic ${this.state.tokens?.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 1) {
          const trip = res.data.data;
          viewModel.localeMoment.locale('en');
          // viewModel.setDate(moment(Date.parse(startDate)).format('YYYY-MM-DD'));
          viewModel.setDate(
            moment.tz(startDate, this.timezone).format('YYYY-MM-DD')
          );
          viewModel.setResources(users);
          viewModel.setEvents(trip);
          const filteredTrip = res.data.data
          this.setState({ trip, viewModel, filteredTrip });
        } else {
          this.setState({
            message: (
              <div className="message">
                <div className="alert alert-danger" role="alert">
                  "An error occurred, try again later"
                </div>
              </div>
            )
          });
          setTimeout(() => { this.setState({ message: "" }) }, 5000);
        }
      })
      .catch(err => {
        console.log(err)
        // if (err.response.status === 401) {
        // this.props.navigate("/login");
        // }
      });
  }


  handleUserChange = (event, newValue) => {
    // If no user is selected (i.e., newValue is null or undefined)
    if (!newValue) {
      const { viewModel } = this.state;
      // Reset users and trips to original unfiltered state
      this.setState(
        {
          selectedUser: null,
          users: this.state.filteredUsers, // Reset to all users
          trip: this.state.filteredTrip // Reset to all trips
        },
        () => {
          viewModel.setResources(this.state.users);
          viewModel.setEvents(this.state.trip);
          this.setState({ viewModel });
        }
      );
    } else {
      // When a user is selected, filter the trips and users accordingly
      this.setState({ selectedUser: newValue }, () => {
        let userFiltered = this.state.filteredUsers.filter(u => u.id === this.state.selectedUser?.id);
        let tripFiltered = this.state.filteredTrip.filter(t => t.resourceId === this.state.selectedUser?.id);
        const { viewModel } = this.state;
        this.setState({ users: userFiltered, trip: tripFiltered }, () => {
          viewModel.setResources(this.state.users);
          viewModel.setEvents(this.state.trip);
          this.setState({ viewModel });
        });
      });
    }
  };

  getWeekStartAndEndDates() {
    const date = new Date();
    const dayOfWeek = date.getDay();
    const startTime = new Date(date);
    const differenceToMonday = (dayOfWeek === 0 ? -6 : 1) - dayOfWeek;
    startTime.setDate(date.getDate() + differenceToMonday);
    const endTime = new Date(startTime);
    endTime.setDate(startTime.getDate() + 6);
    // this.setState({ startDate: moment(Date.parse(startTime)).format('YYYY-MM-DD'), endDate: moment(Date.parse(endTime)).format('YYYY-MM-DD') }, () => {
    //   this.getSchedules();
    // });
    this.setState(
      {
        startDate: moment.tz(startTime, this.timezone).format('YYYY-MM-DD'),
        endDate: moment.tz(endTime, this.timezone).format('YYYY-MM-DD')
      },
      () => {
        this.getSchedules();
      }
    );
  }

  onViewChange(schedulerData, view) {
    schedulerData.setViewType(view.viewType, view.showAgenda, view.isEventPerspective);
    this.getWeekStartAndEndDates();
    schedulerData.setEvents(this.state.trip);
    this.setState({ viewModel: schedulerData });
  }

  onSelectDate(schedulerData, date) {
    schedulerData.setDate(date);
    schedulerData.setEvents(this.state.trip);
    this.setState({ viewModel: schedulerData });
  }

  eventClicked(schedulerData, event) {
    if (event.scheduleId == -100) {
      this.props.navigate("/timeOff/" + event.id);
    } else {
      this.props.navigate("/add/schedule/" + event.scheduleId);
    }
  }


  onScrollRight(schedulerData, schedulerContent, maxScrollLeft) {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.next();
      schedulerData.setEvents(this.state.trip);
      this.setState({ viewModel: schedulerData });
      schedulerContent.scrollLeft = maxScrollLeft - 10;
    }
  }

  onScrollLeft(schedulerData, schedulerContent, maxScrollLeft) {
    if (schedulerData.ViewTypes === ViewTypes.Day) {
      schedulerData.prev();
      schedulerData.setEvents(this.state.trip);
      this.setState({ viewModel: schedulerData });
      schedulerContent.scrollLeft = 10;
    }
  }

  onScrollTop() {
    console.log('onScrollTop');
  }

  onScrollBottom() {
    console.log('onScrollBottom');
  }

  toggleExpandFunc(schedulerData, slotId) {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({ viewModel: schedulerData });
  }

  handleAddClick = () => {
    this.props.navigate("/add/schedule"); // Use navigate from props
  };

  handleStartDateChange = (event) => {
    this.setState({ startDate: event.target.value });
    if (this.state.viewModel.viewType == 0) {
      this.setState({ endDate: event.target.value });
    }
  };

  handleEndDateChange = (event) => {
    this.setState({ endDate: event.target.value });
  };

  render() {
    const { viewModel, message, tokens, selectedUser, startDate, endDate } = this.state;
    return (
      <div className='schedule-container'>
        <div className="filterRow">
          <div>
            <FilterInput
              placeholder="Start Date (yyyy-mm-dd)"
              type="date"
              value={startDate}
              onChange={this.handleStartDateChange}
            />
          </div>
          {viewModel.viewType == 1 ? (
            <div>
              <FilterInput
                placeholder="End Date (yyyy-mm-dd)"
                type="date"
                value={endDate}
                onChange={this.handleEndDateChange}
              />
            </div>) : null}
          <div>
            <Button type="submit" variant="contained" color="primary" className="noShadow w-100 addBtn" onClick={this.getSchedules} >
              Search
            </Button>
          </div>
          {tokens?.userType === "Admin" ? (
            <Autocomplete
              debug="true"
              options={this.state.filteredUsers}
              className="filterAutocomplete"
              getOptionLabel={(option) => option.name || ""}
              value={selectedUser}
              onChange={this.handleUserChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="Select User"
                  className="filterAutocomplete"
                  variant="filled"
                />
              )}
            />
          ) : null}
          <div>
            <Button variant="contained" color="primary" className="noShadow btn-default addBtn " onClick={this.handleAddClick}>
              Add
            </Button>
          </div>
        </div>
        {message}
        <Scheduler
          schedulerData={viewModel}
          onSelectDate={this.onSelectDate}
          onViewChange={this.onViewChange}
          eventItemClick={this.eventClicked}
          // onScrollLeft={this.onScrollLeft}
          // onScrollRight={this.onScrollRight}
          onScrollTop={this.onScrollTop}
          onScrollBottom={this.onScrollBottom}
          toggleExpandFunc={this.toggleExpandFunc}
        />
      </div>
    );
  }
}

const BasicWithRouter = (props) => {
  const navigate = useNavigate(); // Get navigate hook

  return <Basic {...props} navigate={navigate} />;
};

export default withDragDropContext(BasicWithRouter);