import React, { Fragment } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@mui/material';
import { connect } from 'react-redux';
import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';
import navItems from './navItems';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';

const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,
    sidebarShadow
  } = props;

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);

  let tokens = localStorage.getItem("loginRes");
  tokens = JSON.parse(tokens)
  let item;
  if (tokens?.userType == "Leader") {
    item = navItems.map(list => list.content.slice(3, 7))
  }
  else if (tokens?.userType == "User" && tokens?.canAddHours == true) {
    item = navItems.map(list => list.content.slice(4, 9))
    // item =  navItems.map(list => list.content.slice(4,7))
  }
  else if (tokens?.userType == "User" && tokens?.canAddHours == false) {
    item = navItems.map(list => list.content.slice(4, 6))
  }
  else {
    item = navItems.map(list => list.content)
  }

  let visibleItems;

  let data = navItems[0].content;

  if (tokens?.userType === "Leader") {
    visibleItems = [data[1], data[2], data[3], data[4],data[5], data[8], data[9],data[10], data[11], data[12]]; 
  } else if (tokens?.userType == "User" && tokens?.canAddHours == true) {
    visibleItems = [data[3], data[4], data[5], data[8], data[9]]; 
  } else if (tokens?.userType == "User" && tokens?.canAddHours == false) {
    visibleItems = [data[3], data[4], data[8], data[9]]; 
  } else if (tokens?.userType === "Admin") {
    visibleItems = [data[0],data[1], data[2], data[3], data[4],data[5], data[6], data[7], data[9],data[10], data[11], data[12]]; 
  } else {
    // Handle other user types if needed
    visibleItems = [];
  }

  const sidebarMenuContent = (
    <div>
      {
        navItems.map(list => (
          <SidebarMenu
            component="div"
            key={list.label}
            // pages={item[0]}
            pages={visibleItems}
            title={list.label}
          />
        ))
      }
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <br></br>
          <SidebarHeader />
          <br></br>
          <PerfectScrollbar>{sidebarMenuContent}</PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown >
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <br></br>
          <br></br>
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
              {sidebarMenuContent}
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
