import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const BadgeWrapper = styled.span`
    background-color: ${props => props.bgColor};
    color: ${props => props.color};
    height: 14px;
    line-height: 14px;
`;

export function Badge({bgColor, color, children,...props}) {
    
    return (
        <>
            <BadgeWrapper className="mr-1 badge" bgColor={bgColor} color={color} {...props}>{children}</BadgeWrapper>
        </>
    )
}

Badge.propTypes = {
    children: PropTypes.string,
    bgColor: PropTypes.string,
    color: PropTypes.string,
};

Badge.defaultProps = {
    bgColor: '#5383ff',
    color: '#ffffff',
};
  
