import React, { useEffect, useState } from 'react';
import config from '../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useParams } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { Button, TextField, MenuItem } from '@mui/material';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../shared/components/Loader';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
   
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


const headCells = [
  { id: 'fullName', numeric: false, label: 'Name' },
  { id: 'startDate', numeric: false, label: 'Start Date and Time' },
  { id: 'endDate', numeric: false, label: 'End Date and Time' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'center' : 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function TimeOff() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  var [rows, setRows] = React.useState([]);
  var [filteredRows, setFilteredRows] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [editOpenPopup, setEditOpenPopup] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [user, setUser] = React.useState([]);
  const [selectedId, setSeletedId] = React.useState();
  var [hour, setHour] = React.useState(dayjs(''));
  const [deletePopup, setDeletePopup] = React.useState(false);
  let tokens = localStorage.getItem("loginRes");
  tokens = JSON.parse(tokens)
  const userTimeOffAdd = () => toast("User TimeOff Added Successfully!");
  const userTimeOffUpdate = () => toast("User TimeOff Updated Successfully!");
  const userTimeOffDelete = () => toast("User TimeOff deleted successfully!");
  const userTimeOffError = () => toast("An error occured, please try again later!");
  const { id } = useParams();


  useEffect(() => {
    userTimeOffList();
    if (id) {
      handleEditOpenPopup(id)
    }
  }, [])

  const userTimeOffList = () => {
    setLoading(true);
    setTimeout(() => {
      const RAFTCO_TIMEOFF_URL = config.API_URL + "/api/UserTimeOff"
      axios.get(RAFTCO_TIMEOFF_URL, {
        headers: {
          'Authorization': `Basic ${tokens.jwtToken}`
        }
      })
        .then((res) => {
          if (res.data.successCode === 1) {
            filteredRows = res.data.data
            if (tokens.userType !== "Admin") {
              filteredRows = filteredRows.filter(u => u.userId === tokens.id);
            }
            setFilteredRows(filteredRows)
          } else {
            userTimeOffError();
          }
        }).finally(() => {
          setLoading(false);
        });
    })
  }

  const userTimeOffId = (id) => {
    const RAFTCO_TIMEOFF_URL = config.API_URL + "/api/userTimeOff/" + id
    axios.get(RAFTCO_TIMEOFF_URL, {
      headers: {
        'Authorization': `Basic ${tokens.jwtToken}`
      }
    })
      .then((res) => {
        if (res.data.successCode === 1) {
          rows = res.data.data;
          setRows(rows);
          if (rows.length != 0) {
            setEditOpenPopup(true);
          }
        } else {
          userTimeOffError();
        }
      })
  }

  const UsersList = () => {
    setLoading(true);
    setTimeout(() => {
      const RAFTCO_USER_URL = config.API_URL + "/api/Users"
      axios.get(RAFTCO_USER_URL, {
        headers: {
          'Authorization': `Basic ${tokens.jwtToken}`
        }
      })
        .then((res) => {
          if (res.data.successCode === 1) {
            const users = res.data.data;
            setUserList(users);
          } else {
            userTimeOffError();
          }
        }).finally(() => {
          setLoading(false);
        });
    }, 1000);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleOpenPopup = () => {
    const openPopup = true
    setOpenPopup(openPopup);
    if (tokens.userType === "Admin") {
      UsersList();
    }
  }

  const handleEditOpenPopup = (id) => {
    const selectedRow = id
    setSelectedRow(selectedRow);
    userTimeOffId(id);
    if (tokens.userType === "Admin") {
      UsersList();
    }
  }

  const handleClosePopup = () => {
    const openPopup = false
    setOpenPopup(openPopup);
  }

  const handleEditClose = () => {
    const editOpenPopup = false
    setEditOpenPopup(editOpenPopup)
  }

  const openDeletePopup = (id) => {
    const selectedId = id
    setSeletedId(selectedId);
    const deletePopup = true
    setDeletePopup(deletePopup);
  }

  const deleteUserTimeOff = () => {
    setLoading(true)
    setTimeout(() => {
      const RAFTCO_DELETE_URL = config.API_URL + "/api/UserTimeOff/delete/" + selectedId
      axios.delete(RAFTCO_DELETE_URL, {
        headers: {
          'Authorization': `Bearer ${tokens.jwtToken}`
        }
      })
        .then((res) => {
          if (res.data.successCode === 1) {
            setTimeout(() => { userTimeOffDelete(); }, 1000)
            userTimeOffList();
          } else {
            setTimeout(() => { userTimeOffError(); }, 1000)
          }
        }).finally(() => {
          setDeletePopup(false)
          setLoading(false);
        });
    })
  }

  if (loading) {
    return (
      <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
        <Loader color="#ff9900" />
      </div>
    );
  }

  return (

    <TableWrapper>
      {/* {message} */}
      <ToastContainer theme='dark' />
      <div className={` ${classes.root} materialTableStyle`}>
        <div className="filterRow">
          <div>
          </div>
          <div>

          </div>
          <div>

          </div>
          <div className="m-2">
            <Button variant="contained" color="primary" className="noShadow w-100 addBtn " onClick={handleOpenPopup}>
              Add
            </Button>
          </div>
        </div>
        <TableContainer>
          <Dialog open={openPopup} onClose={handleClosePopup} closeonescape="true" fullWidth={true} maxWidth="sm">
            <TableWrapper>
              <DialogTitle>
                <div className="text-right" onClick={handleClosePopup}>
                  <FontAwesomeIcon icon={faWindowClose} className="closeIcon" />
                </div>
                Time Schedule
              </DialogTitle>
              <DialogContent className="dialogStyle">
                <Formik
                  enableReinitialize={true}
                  initialValues={{ fullName: '', startDate: '', endDate: '', Time: rows?.time }}
                  validate={(values) => {

                    const errors = {};

                    if (tokens.userType === 'Admin' && !values.fullName) {
                      errors.fullName = 'Required!';
                    }

                    if (!values.startDate) {
                      errors.startDate = 'Required!';
                    }

                    if (!values.endDate) {
                      errors.endDate = 'Required!';
                    }

                    return errors;
                  }}

                  const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                  }}

                  onSubmit={(values, { setSubmitting }) => {
                    setLoading(true)
                    setTimeout(() => {
                      const postData = {
                        // id: 0,
                        userId: values.userId,
                        fullName: values.fullName,
                        startDate: values.startDate ? dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                        endDate: values.endDate ? dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                        isActive: true,
                      }
                      let tokens = localStorage.getItem("loginRes");
                      tokens = JSON.parse(tokens);
                      const RAFTCO_TIMEOFF_URL = config.API_URL + "/api/userTimeOff/add"
                      axios.post(RAFTCO_TIMEOFF_URL, postData, {
                        headers: {
                          'Authorization': `Basic ${tokens.jwtToken}`
                        }
                      })
                        .then((res) => {
                          if (res.data.successCode === 1) {
                            setTimeout(() => { userTimeOffAdd(); }, 1000)
                            userTimeOffList();
                          } else {
                            setTimeout(() => { userTimeOffError(); }, 1000)
                          }
                        })
                        .catch(() => {
                          setTimeout(() => { userTimeOffError(); }, 1000)
                        })
                        .finally(() => {
                          setOpenPopup(false)
                          setLoading(false);
                          setSubmitting(false);
                        });
                    })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {tokens.userType == "Admin" &&
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField style={{ width: "80%" }}
                            className="filterDropdown"
                            id="fullName"
                            select
                            label="Select User"
                            helperText=""
                            variant="standard"
                            name="fullName"
                            value={values.fullName || ""}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                fullName: e.target.value,
                                userId: userList.find(user => user.fullName === e.target.value)?.userId
                              })}
                            onBlur={handleBlur}
                          >
                            {userList.map(option => (
                              <MenuItem key={option.userId} value={option.fullName}>
                                {option.fullName}
                              </MenuItem>
                            ))}
                          </TextField>
                          <div className="errMsg" style={{ marginTop: '5px' }}>{errors.fullName && touched.fullName && errors.fullName}</div>
                        </Grid>
                      }
                      <Grid item xs={12} sm={6} md={6} style={{ marginTop: '16px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DateTimePicker', 'DateTimePicker', 'DateTimePicker']}
                          >
                            <DemoItem
                              label={'Start Date'}
                            >
                              <DateTimePicker
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                onChange={e => { setFieldValue('startDate', e) }}
                                value={values.startDate ? dayjs(values.startDate) : null}
                                slotProps={{ textField: { style: { width: "80%" } } }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.startDate && touched.startDate && errors.startDate}</div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} style={{ marginTop: '16px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DateTimePicker', 'DateTimePicker', 'DateTimePicker']}
                          >
                            <DemoItem
                              label={'End Date'}
                            >
                              <DateTimePicker
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                onChange={e => { setFieldValue('endDate', e) }}
                                value={values.endDate ? dayjs(values.endDate) : null}
                                slotProps={{ textField: { style: { width: "80%" } } }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.endDate && touched.endDate && errors.endDate}</div>
                      </Grid>
                      <DialogActions>
                        <Grid container spacing={4} className="mt-3">
                          <Grid item xs={12} sm={12} md={12} className="text-right">
                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleClosePopup}>
                              Cancel
                            </Button>&nbsp;&nbsp;
                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting}>
                              Save
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogActions>
                    </form>
                  )}
                </Formik>
              </DialogContent>
            </TableWrapper>
          </Dialog>

          <Dialog open={editOpenPopup} onClose={handleEditClose} closeonescape="true" fullWidth={true} maxWidth="sm">
            <TableWrapper>
              <DialogTitle>
                <div className="text-right">
                  <FontAwesomeIcon icon={faWindowClose} className="closeIcon" onClick={handleEditClose} />
                </div>
                Time Schedule
              </DialogTitle>
              <DialogContent className="dialogStyle">
                <Formik
                  enableReinitialize
                  initialValues={{
                    fullName: rows?.fullName || "",
                    startDate: rows?.startDate,
                    endDate: rows?.endDate,
                    UserId: rows?.userId || "",
                    // Time: rows?.endDate ? dayjs(rows.endDate.split('T')[1], 'HH:mm:ss') : null,
                  }}
                  validate={values => {

                    const errors = {};

                    if (tokens.userType === 'Admin' && !values.fullName) {
                      errors.fullName = 'Required!';
                    }

                    if (!values.startDate) {
                      errors.startDate = 'Required!';
                    }

                    if (!values.endDate) {
                      errors.endDate = 'Required!';
                    }

                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    setLoading(true)
                    setTimeout(() => {
                      const postData = {
                        id: selectedRow,
                        userId: values.UserId,
                        fullName: values.fullName,
                        startDate: values.startDate ? dayjs(values.startDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                        endDate: values.endDate ? dayjs(values.endDate).format('YYYY-MM-DDTHH:mm:ss') : null,
                        // startDate: `${values.startDate}T${values.Time.$H.toString().padStart(2, '0') + ":" + values.Time.$m.toString().padStart(2, '0') + ":00"}`,
                        // endDate: `${values.endDate}T${values.Time.$H.toString().padStart(2, '0') + ":" + values.Time.$m.toString().padStart(2, '0') + ":00"}`,
                        // time: values.Time.$H.toString().padStart(2, '0') + ":" + values.Time.$m.toString().padStart(2, '0') + ":00",
                        isActive: true,
                      }
                      let tokens = localStorage.getItem("loginRes");
                      tokens = JSON.parse(tokens);
                      const RAFTCO_TIMEOFF_URL = config.API_URL + "/api/userTimeOff/update"
                      axios.post(RAFTCO_TIMEOFF_URL, postData, {
                        headers: {
                          'Authorization': `Basic ${tokens.jwtToken}`
                        }
                      })
                        .then((res) => {
                          if (res.data.successCode === 1) {
                            setTimeout(() => { userTimeOffUpdate(); }, 1000)
                            userTimeOffList();
                          } else {
                            setTimeout(() => { userTimeOffError(); }, 1000)
                          }
                        })
                        .catch(() => {
                          setTimeout(() => { userTimeOffError(); }, 1000)
                        })
                        .finally(() => {
                          // setTimeout(() => { setEditOpenPopup(false) }, 500);
                          setEditOpenPopup(false);
                          setLoading(false);
                          setSubmitting(false);
                        });
                    })
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      {tokens.userType == "Admin" &&
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            style={{ width: "80%" }}
                            className="filterDropdown"
                            id="fullName"
                            select
                            label="Select User"
                            helperText=""
                            variant="standard"
                            name="fullName"
                            value={values.fullName || ""}
                            onChange={(e) =>
                              setValues({
                                ...values,
                                fullName: e.target.value,
                                UserId: userList.find(user => user.fullName === e.target.value)?.userId
                              }
                              )}
                            onBlur={handleBlur}
                          >
                            {userList.map(option => (
                              <MenuItem key={option.userId} value={option.fullName}>
                                {option.fullName}
                              </MenuItem>
                            ))}
                          </TextField>
                          <div className="errMsg" style={{ marginTop: '5px' }}>{errors.fullName && touched.fullName && errors.fullName}</div>
                        </Grid>
                      }
                      <Grid item xs={12} sm={6} md={6} style={{ marginTop: '16px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DateTimePicker', 'DateTimePicker', 'DateTimePicker']}
                          >
                            <DemoItem
                              label={'Start Date'}
                            >
                              <DateTimePicker
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                onChange={e => { setFieldValue('startDate', e) }}
                                value={values.startDate ? dayjs(values.startDate) : null}
                                slotProps={{ textField: { style: { width: "80%" } } }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.startDate && touched.startDate && errors.startDate}</div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={6} style={{ marginTop: '16px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer
                            components={['DateTimePicker', 'DateTimePicker', 'DateTimePicker']}
                          >
                            <DemoItem
                              label={'End Date'}
                            >
                              <DateTimePicker
                                views={['year', 'month', 'day', 'hours', 'minutes']}
                                onChange={e => { setFieldValue('endDate', e) }}
                                value={values.endDate ? dayjs(values.endDate) : null}
                                slotProps={{ textField: { style: { width: "80%" } } }}
                              />
                            </DemoItem>
                          </DemoContainer>
                        </LocalizationProvider>
                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.endDate && touched.endDate && errors.endDate}</div>
                      </Grid>
                      <DialogActions>
                        <Grid container spacing={4} className="mt-3">
                          <Grid item xs={12} sm={12} md={12} className="text-right">
                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={handleEditClose}>
                              Cancel
                            </Button>&nbsp;&nbsp;
                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting}>
                              Update
                            </Button>
                          </Grid>
                        </Grid>
                      </DialogActions>
                    </form>
                  )}
                </Formik>
              </DialogContent>
            </TableWrapper>
          </Dialog>

          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>

              {stableSort(filteredRows, getComparator(order, orderBy))
                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((filteredRows, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={filteredRows.id}
                    >
                      <TableCell>
                        {filteredRows.fullName}
                      </TableCell>
                      <TableCell align="left">
                        {dayjs(filteredRows.startDate).format('YYYY-MM-DD h:mm A')}
                      </TableCell>
                      <TableCell align="left">
                        {dayjs(filteredRows.endDate).format('YYYY-MM-DD h:mm A')}
                        <div className="actionListEditIconPosition">
                          <Tooltip title="Edit" aria-label="edit">
                            <IconButton className="actionBtn" aria-label="edit" onClick={() => handleEditOpenPopup(filteredRows.id)}>
                              <EditIcon className="actionIcon" />
                            </IconButton>
                          </Tooltip>
                        </div>
                        <div className="actionListDeleteIconPosition">
                          <Tooltip title="delete" aria-label="delete">
                            <IconButton className="actionBtn" aria-label="delete" onClick={() => { openDeletePopup(filteredRows.id) }}>
                              <DeleteIcon className="actionIcon" />
                            </IconButton>
                          </Tooltip>
                          <Dialog open={deletePopup} closeonescape="true" maxWidth="true" >
                            <TableWrapper>
                              <DialogContent className="dialogStyle">
                                <div className={`${classes.root} materialTableStyle`}>
                                  Do you want to delete the record?
                                </div>
                                <DialogActions>
                                  <Grid container spacing={4} className="mt-1">
                                    <Grid item xs={12} sm={12} md={12} className="text-center">
                                      <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setDeletePopup(false)}>
                                        No
                                      </Button>&nbsp;&nbsp;
                                      <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => deleteUserTimeOff()}>
                                        Yes
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </DialogActions>
                              </DialogContent>
                            </TableWrapper>
                          </Dialog>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <TablePagination
                    className="paginationLabel"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
      </div>
    </TableWrapper>
  );
}