import React, { useEffect, useState } from 'react';
import config from '../config.json';
import axios from 'axios';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { Loader } from '../../shared/components/Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FilterInput } from '../../shared/components/FilterInput';
import Box from '@mui/material/Box';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Card, CardContent, Grid, Typography, List, ListItem, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Rating from '@mui/material/Rating';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';

const TableWrapper = styled.div`
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr 3fr 3fr 3fr 3fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .card {
        display: flex;
        alignItems: 'center';
        justify-content: center;
        height: 150px;
    }
    cardContent {
      display: flex;
      justifyContent: center; 
      alignItems: center;
    }
    .card-1 {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        min-height: 150px;
        overflow: hidden;
    }
    .card-1:hover {
        box-shadow: 0 14px 10px rgba(0,0,0,0.25), 0 6px 6px rgba(0,0,0,0.10);
    }
    .card-content {
        overflow-y: auto;
        flex-grow: 1;
        padding: 10px;
    }
    .accordion {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        margin-top: 15px;
        overflow: hidden;
    }
   .table-container {
        max-height: 200px;
        overflow-y: auto;
    }
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                }
            }
        }
}
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    greenButton: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'darkgreen',
        },
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCellsListOfShuttlePopup = [
    { id: 'shuttleName', numeric: false, label: 'Shuttle Name' },
    { id: 'employee', numeric: false, label: 'User' },
    { id: 'totalCounnt', numeric: false, label: 'Count' }
]

function EnhancedTableHeadShuttlePopup(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCellsListOfShuttlePopup.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHeadShuttlePopup.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function ScheduleList() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens);
    const navigate = useNavigate();
    const [startDate, setStartDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [endDate, setEndDate] = React.useState(null);
    const [rows, setRows] = React.useState([]);
    const [nameList, setNameList] = React.useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [shuttleFilteredRows, setShuttleFilteredRows] = React.useState([]);
    const [shuttlePopup, setShuttlePopup] = React.useState(false);
    const [isStandby, setisStandby] = useState(false);
    const [loading, setLoading] = useState(false);
    const errorMessage = () => toast("An error occured, please try again later!");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        scheduleSearch()
    }, [])

    const scheduleSearch = () => {
        setLoading(true);
        setTimeout(() => {
            const postData = {
                "userId": -1,
                "startTime": startDate,
                "endTime": startDate
            }
            console.log(postData);
            const RAFTCO_DAILY_SCHEDULE = config.API_URL + "/Schedule/api/Schedule/search/daily";
            axios.post(RAFTCO_DAILY_SCHEDULE, postData, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        const rows = res.data.data
                        setRows(rows);
                        const filteredRows = rows
                        setFilteredRows(filteredRows);
                        const nameList = filteredRows.map(r => r.tripItems)
                        setNameList(nameList);
                    } else {
                        errorMessage();
                    }
                }).finally(() => {
                    setLoading(false);
                })
        })
    }

    const viewShuttle = () => {
        setShuttlePopup(true);
        const RAFTCO_TRIP_SCHEDULE = config.API_URL + "/schedule/api/schedule/shuttleallocation/" + startDate.split('-').join('')
        axios.get(RAFTCO_TRIP_SCHEDULE, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    const shuttleFilteredRows = res.data.data
                    setShuttleFilteredRows(shuttleFilteredRows);
                } else {
                    errorMessage();
                }
            })
    }

    const editSchedule = (filteredRows) => {
        navigate("/add/schedule/" + filteredRows.scheduleId);
    }

    const editTimeOff = (filteredRows) => {
        navigate("/timeOff/" + filteredRows.scheduleId);
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    return (
        <TableWrapper>
            <ToastContainer theme='dark' />
            <Dialog open={shuttlePopup} onClose={() => setShuttlePopup(false)} closeonescape="true" fullWidth={true} maxWidth="sm">
                <TableWrapper>
                    <DialogContent>
                        <div className="text-right" onClick={() => setShuttlePopup(false)}>
                            <FontAwesomeIcon icon={faWindowClose} className="closeIcon" />
                        </div>
                        <TableContainer>
                            <Table
                                className="materialTableStyle"
                                aria-labelledby="tableTitle"
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHeadShuttlePopup
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(shuttleFilteredRows, getComparator(order, orderBy))
                                        .map((shuttleFilteredRows, index) => {
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={shuttleFilteredRows.shuttleId}
                                                >
                                                    <TableCell>{shuttleFilteredRows.shuttleName}</TableCell>
                                                    <TableCell>{shuttleFilteredRows.employee}</TableCell>
                                                    <TableCell>{shuttleFilteredRows.totalCounnt}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Grid item xs={12} sm={12} md={12} className="text-center">
                            <Button variant="outlined" color="primary" className="noShadow cancelBtn " onClick={() => setShuttlePopup(false)}>
                                Cancel
                            </Button>
                        </Grid>
                    </DialogActions>
                </TableWrapper>
            </Dialog>
            <div className='schedule-container'>
                <div className="filterRow">
                    <div>
                        <div>
                            <FilterInput placeholder="Start Date(yyyy-mm-dd)" type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                        </div>
                    </div>
                    <div>
                        <Button type="submit" variant="contained" color="primary" className="noShadow w-100 addBtn" onClick={() => scheduleSearch()}>
                            Search
                        </Button>
                    </div>
                    <div>
                        <Button type="button" variant="contained" color="primary" className="noShadow addBtn" style={{ whiteSpace: 'noWrap' }} onClick={viewShuttle}>
                            View Shuttle
                        </Button>
                    </div>
                    <div>
                        <Button variant="contained" color="primary" className="noShadow btn-default addBtn" onClick={() => navigate("/add/schedule")}>
                            Add
                        </Button>
                    </div>
                </div>
                <Grid container spacing={2} style={{ marginTop: '20px' }}>
                    {filteredRows.length > 0 ? (filteredRows.map((schedule) => {
                        const roleEntries = Object.entries(schedule.roles);
                        const nameList = schedule.tripItems;
                        return (
                            <Grid item xs={12} sm={6} md={6} key={schedule.scheduleId}>
                                {schedule.scheduleId !== -100 && (
                                    <Card className="card-1" onClick={() => { editSchedule(schedule) }} style={{ cursor: 'pointer' }}>
                                        <CardContent className='card-content'>
                                            <Typography variant="body1" style={{ fontWeight: 'bold', textDecoration: 'underline', color: '#F59300' }}>
                                                {schedule.tripName} ({schedule.clientCount}, {schedule.openTo})
                                            </Typography>
                                            {/* {roleEntries.map(([roleName, count]) => (
                                                <Typography variant="body2" style={{ fontSize: '14px' }}>
                                                    {roleName} ({count})
                                                </Typography>
                                            ))} */}
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell> Employees</TableCell>
                                                            <TableCell>Role</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {nameList.length > 0 && nameList.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    {item.userName}
                                                                    {item.isStandby === true && (<Rating name="half-rating-read" defaultValue={1} precision={0.5} max={1} size="small" readOnly style={{ verticalAlign: 'middle' }} />)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {item.roleName}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                )}
                                {schedule.scheduleId === -100 && (
                                    <Card className="card-1" onClick={() => editTimeOff(schedule)} style={{ cursor: 'pointer' }}>
                                        <CardContent className='card-content'>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Employees</TableCell>
                                                            <TableCell>Start Date and Time</TableCell>
                                                            <TableCell>End Date and Time</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {nameList.length > 0 && nameList.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    {item.userName}
                                                                    {item.isStandby === true && (<Rating name="half-rating-read" defaultValue={1} precision={0.5} max={1} size="small" readOnly style={{ verticalAlign: 'middle' }} />)}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {dayjs(item.startDate).format('YYYY-MM-DD h:mm A')}
                                                                </TableCell>
                                                                <TableCell>
                                                                    {dayjs(item.endDate).format('YYYY-MM-DD h:mm A')}
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </CardContent>
                                    </Card>
                                )}
                            </Grid>
                        )
                    })
                    ) : (
                        <Grid item xs={12} sm={6} md={6}>
                            <Card className="card">
                                <CardContent className="cardContent">
                                    <Typography variant="body1" style={{ fontWeight: 'bold', textAlign: 'center' }}>
                                        Schedules Not Found
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </div>
        </TableWrapper>
    )
}