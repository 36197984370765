import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import { Button, FormControl, Grid, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from '../../../shared/components/Loader';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { useParams } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Dialog, DialogContent, DialogActions } from '@mui/material';



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
.css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #F59300 !important;
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    border : 1px solid #F59300 !important;
    color : #F59300 !important;
}
    position: relative;

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
`;

export default function UpdateTrip() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    var [rolesList, setRolesList] = useState([]);
    var [userList, setUserList] = useState([]);
    var [role, setRole] = useState({ "roleId": 0 });
    const [rate, setRate] = useState(0);
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    var [tripItems, setTripItems] = useState([]);
    const [showRoles, setShowRoles] = useState(false);
    var [leaderList, setLeaderList] = useState([]);
    const { id } = useParams();
    const [showHours, setShowHours] = useState(false);
    const [minutes, setMinutes] = useState([]);
    var [tripDateArray, setTripDateArray] = useState([]);
    var [tripDate, setTripDate] = useState("");
    var [tripUserId, setTripUserId] = useState();
    var [tripLeaderId, setTripLeaderId] = useState(null);
    var [rows, setRows] = useState(
        {
            "leaderName": {
                "userId": 0
            },
            "isActive": false,
            "tripDate": "",
            "tripItems": [{ "userId": 0, "roleId": 0 }],
            "tripName": ""
        })
    var [user, setUser] = useState({});
    const [disableUserList, setDisableUserList] = useState(false);
    const [disableLeaderList, setDisableLeaderList] = useState(false);
    const navigate = useNavigate();
    const todayDate = moment(Date.parse(new Date())).format('YYYY-MM-DD');
    var [tripName, setTripName] = useState("");
    var [unfilteredRolesList, setUnfilteredRolesList] = useState([]);
    var [roleNotes, setRoleNotes] = useState("");
    var [rowIndex, setRowIndex] = useState();
    const [loading, setLoading] = useState(false);
    var [deletePopup, setDeletePopup] = React.useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [showRate, setShowRate] = useState(false);
    const tripUpdated = () => toast("Trip Updated Successfully!");
    const tripUpdateError = () => toast("An error occured, please try again later!");


    const useEffectOnce = (effect) => {

        const destroyFunc = useRef();
        const effectCalled = useRef(false);
        const renderAfterCalled = useRef(false);
        const [val, setVal] = useState(0);

        if (effectCalled.current) {
            renderAfterCalled.current = true;
        }

        useEffect(() => {

            // only execute the effect first time around
            if (!effectCalled.current) {
                destroyFunc.current = effect();
                effectCalled.current = true;
            }

            // this forces one render after the effect is run
            setVal(val => val + 1);

            return () => {
                // if the comp didn't render since the useEffect was called,
                // we know it's the dummy React cycle
                if (!renderAfterCalled.current) { return; }
                if (destroyFunc.current) { destroyFunc.current(); }
            };
        }, []);
    };


    useEffectOnce(() => {
        setLoading(true);
        setTimeout(() => {
            const RAFTCO_USER_URL = config.API_URL + "/api/Users"
            axios.get(RAFTCO_USER_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        let users = res.data.data
                        users = users.filter(u => u.isActive == true)
                        // userList = users.filter(u => u.userType == "User")
                        userList = users
                        setUserList(userList);
                        //leaderList = users.filter(u => u.userType == "Leader")
                        leaderList = users
                        setLeaderList(leaderList)
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                })
            const RAFTCO_ROLES_URL = config.API_URL + "/api/Roles"
            axios.get(RAFTCO_ROLES_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        rolesList = res.data.data
                        setRolesList(rolesList);
                        setUnfilteredRolesList(rolesList)
                        if (tokens.userType == "User" && tokens.canAddHours == true) {
                            let hourlyRoles;
                            hourlyRoles = rolesList.filter(r => r.roleName.startsWith("Hour"))
                            setRolesList(hourlyRoles)
                        }
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                })
            const RAFTCO_TRIP_URL = config.API_URL + "/Trip/api/Trip/" + id
            axios.get(RAFTCO_TRIP_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        rows = res.data.data;
                        setRows(rows);
                        tripDateArray = rows.tripDate?.split("T")
                        setTripDateArray(tripDateArray);
                        tripDate = tripDateArray[0];
                        setTripDate(tripDate)
                        tripName = rows.tripName
                        setTripName(tripName)
                        if (tripName == "Others") { setShowHours(true); }
                        else { setShowHours(false) }
                        tripItems = rows.tripItems;
                        setTripItems(tripItems)
                        if (tokens.userType == "Leader") {
                            tripLeaderId = tokens.id;
                            setTripLeaderId(tripLeaderId)
                            setDisableLeaderList(true)
                        } else {
                            tripLeaderId = rows.leaderName?.userId
                            setTripLeaderId(tripLeaderId)
                        }
                        if (tokens.userType == "User") {
                            tripUserId = tokens.id;
                            setTripUserId(tripUserId)
                            setDisableUserList(true)
                        }
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }

                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                }).finally(() => {
                    setLoading(false);
                })
        })
        Hours()

    })
    const notifyAddRoles = () => toast("Select Staff & Role !");

    const addRoles = (values) => {
        if (!role.roleId || !tripUserId) {
            notifyAddRoles()
        } else {
            tripItems.push({
                roleId: role.roleId, roleName: role.roleName, userId: tripUserId,
                rate: role?.roleName?.toLowerCase() == "gratuity" ? values.Rate : role.rate, hours: values.Hours, operationType: "A", notes: roleNotes
            })
            setTripItems(tripItems)
            values.Hours = '';
            tokens.userType != "User" && setTripUserId(0);
            setRole("")
            setRate(0)
            setRoleNotes("")
            setShowRoles(true)
        }
    }

    const deleteRole = () => {
        tripItems[rowIndex].operationType = "D";
        setTripItems([...tripItems])
        deletePopup = false;
        setDeletePopup(deletePopup);
    }

    const openDeletePopup = (i) => {
        rowIndex = i
        setRowIndex(rowIndex)
        deletePopup = true;
        setDeletePopup(deletePopup);
    }

    const displayRoles = () => {
        const tripArray = tripItems?.filter(t => t?.operationType != 'D')
        if (tripArray.length > 0)
            return (
                <Grid container spacing={4} className="pt-4">
                    <Grid item xs={12} sm={12} md={12}>
                        <table className="table table-hover table-bordered p-5" style={{ width: "90%" }}>
                            <thead>
                                <tr>
                                    <th scope="col">Staff</th>
                                    <th scope="col">Role</th>
                                    <th scope="col">Rate</th>
                                    <th scope="col">Hours</th>
                                    <th scope="col">Notes</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tripItems.map((r, index) => {
                                    user = userList.find(u => u.userId == r.userId)
                                    setUser(user)
                                    if (r.operationType !== "D")
                                        return (
                                            <tr key={r.roleId + "" + r.userId}>
                                                <td>{user?.fullName}</td>
                                                <td>{r.roleName}</td>
                                                {r?.roleName?.toLowerCase() == "gratuity" ? <td>{'\u0024' + r.rate}</td> : <td></td>}
                                                <td>{r.hours}</td>
                                                <td>{r.notes}</td>
                                                <td>
                                                    <div className="actionListDeleteIconPosition">
                                                        <Tooltip title="delete" aria-label="delete">
                                                            <IconButton className="actionBtn" aria-label="delete" onClick={() => openDeletePopup(index)} >
                                                                <DeleteIcon className="actionIcon" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Dialog open={deletePopup} closeonescape="true" maxWidth="true" >
                                                            <DialogContent className="dialogStyle">
                                                                <div className={`${classes.root} materialTableStyle`}>
                                                                    Do you want to delete the record?
                                                                </div>
                                                                <DialogActions>
                                                                    <Grid container spacing={4} className="mt-1">
                                                                        <Grid item xs={12} sm={12} md={12} className="text-center">
                                                                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setDeletePopup(false)}>
                                                                                No
                                                                            </Button>&nbsp;&nbsp;
                                                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => deleteRole()}>
                                                                                Yes
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </DialogActions>
                                                            </DialogContent>
                                                        </Dialog>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                })}
                            </tbody>
                        </table>
                    </Grid>
                </Grid >
            )
    }

    const minTwoDigits = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    const Hours = () => {
        for (let index = 1; index < 41; index++) {
            const x = index;
            let min = 0;

            if (x < 13) {
                for (let jindex = 0; jindex < 3; jindex++) {
                    min = min + 15;
                    minutes.push({
                        value: `${minTwoDigits(x - 1)}:${min}:00`,
                        display: `${minTwoDigits(x - 1)}:${min}`
                    })
                    setMinutes(minutes)
                }
            }

            minutes.push({
                value: `${minTwoDigits(x)}:00:00`,
                display: `${minTwoDigits(x)}:00`
            })
            setMinutes(minutes)
        }

    }

    const displayHoursOrRate = (handleChange, values, handleBlur) => {
        if (showHours)
            return (
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl style={{ width: "60%" }}>
                        <TextField
                            label="Hours"
                            select
                            value={values.Hours}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="Hours"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                            {minutes.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.display}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                </Grid>
            )
        if (showRate)
            return (
                <Grid item xs={12} sm={6} md={6}>
                    <FormControl style={{ width: "60%" }}>
                        <TextField
                            label="Rate"
                            value={values.Rate}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="Rate"
                            variant="standard"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        >
                        </TextField>
                    </FormControl>
                </Grid>
            )
    }

    if (tokens.userType == "User" && tokens.canAddHours == true) {
        var tripList = ["Hourly Work"]
    }
    else {
        var tripList = ["2 Day",
            "Babine",
            "Cheak AM",
            "Cheak PM",
            "Elaho AM",
            "Elaho PM",
            "Hourly Work",
            "Twilight PM"
        ]
    }
    const handleUser = (e) => {
        if (tripItems.find(t => t.userId == e.target.value && t.operationType != "D")) {
            setOpenPopup(true);
            setTripUserId(e.target.value)
        } else {
            setTripUserId(e.target.value)
        }
    }



    const handleTripName = (e) => {
        tripName = e.target.value
        setTripName(tripName)
        // if (tripName == "Others") {
        //     let hourlyRoles;
        //     hourlyRoles = rolesList.filter(r => r.roleName.startsWith("Hour"))
        //     setRolesList(hourlyRoles)
        //     setShowHours(true)
        // }
        // else {
        //     setRolesList(unfilteredRolesList)
        //     setShowHours(false)
        // }
    }

    const notifyClickAdd = () => toast("You forgot to click the add button!");

    const addDuplicateUser = (e) => {
        setOpenPopup(false)
    }

    const handleRole = (e) => {
        {
            role = e.target.value
            setRole(role);
            if (role?.roleName?.startsWith("Hour")) {
                setShowHours(true);
            }
            else { setShowHours(false) }
            if (role?.roleName == "Gratuity") {
                setShowRate(true);
            }
            else { setShowRate(false) }
        }
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }


    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                enableReinitialize={true}
                initialValues={{ TripDateTime: tripDate, TripName: rows?.tripName, TripLeaderId: rows?.leaderName?.userId, UserId: 0, Notes: rows?.notes, Hours: '', Rate: 0 }}
                validate={values => {
                    const errors = {};
                    if (!values.TripDateTime) {
                        errors.TripDateTime = 'Required!';
                    }
                    if (!tripName) {
                        errors.TripName = 'Required!';
                    }
                    if (!tripLeaderId) {
                        errors.TripLeaderId = 'Required!';
                    }

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}

                onSubmit={(values, { setSubmitting }) => {
                    if ((tripUserId != undefined && tripUserId != 0) && role != "") {
                        notifyClickAdd()
                        setSubmitting(false)
                    } else {
                        setLoading(true);
                        setTimeout(() => {
                            const postData = {
                                tripId: id,
                                tripDate: values.TripDateTime,
                                tripName: tripName,
                                tripLeaderId: tripLeaderId,
                                tripItems: tripItems,
                                notes: values.Notes,
                                loginId: tokens.id
                            }

                            const RAFTCO_URL = config.API_URL + "/Trip/api/Trip/update"
                            axios.post(RAFTCO_URL, postData, {
                                headers: {
                                    'Authorization': `Basic ${tokens.jwtToken}`
                                }
                            })
                                .then((res) => {

                                    if (res.data.successCode === 1) {
                                        setTimeout(() => { tripUpdated() }, 2000)
                                        // setMessage(
                                        //     <div className="message">
                                        //         <div className="alert alert-success" role="alert">
                                        //             Trip updated successfully!
                                        //         </div>
                                        //     </div>)
                                    } else {
                                        setTimeout(() => { tripUpdateError() }, 2000)
                                        // setMessage(
                                        //     <div className="message">
                                        //         <div className="alert alert-danger" role="alert">
                                        //             "An error occured,try again later"
                                        //         </div>
                                        //     </div>)
                                    }
                                    setTimeout(() => { navigate("/trip/list") }, 5000)
                                }).finally(() => {
                                    setLoading(false);
                                })

                            setSubmitting(false)
                        })
                    }
                }
                }
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            {message}
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6} className="mb-3">
                                    <PageTitle>Trip</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className="text-right">

                                </Grid>
                            </Grid>

                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl style={{ width: "55%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            id="Date"
                                            label="Trip Date"
                                            name="TripDateTime"
                                            type="date"
                                            color="primary"
                                            value={values.TripDateTime}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{ inputProps: { max: todayDate } }}
                                            variant="standard"
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl style={{ width: "55%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            d="trip name"
                                            label="Trip Name"
                                            type="text"
                                            color="primary"
                                            name="TripName"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                            select
                                            value={tripName}
                                            onChange={(e) => handleTripName(e)}
                                            onBlur={handleBlur}
                                        >
                                            {tripList.map(option => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <span className="errMsg">{errors.TripName && touched.TripName && errors.TripName}</span>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl style={{ width: "55%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            label="Submitted By"
                                            select
                                            value={tripLeaderId}
                                            onBlur={handleBlur}
                                            onChange={(e) => setTripLeaderId(e.target.value)}
                                            name="TripLeaderId"
                                            variant="standard"
                                            disabled={disableLeaderList}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}>
                                            {leaderList.map(option => (
                                                <MenuItem key={option.userId} value={option.userId}>
                                                    {option.fullName}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <span className="errMsg">{errors.TripLeaderId && touched.TripLeaderId && errors.TripLeaderId}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <FormControl style={{ width: "55%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            id="notes"
                                            label="Notes"
                                            name="Notes"
                                            type="text"
                                            rows="2"
                                            color="primary"
                                            value={values.Notes}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br></br>
                            <br></br>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12} className="mx-4" style={{ border: "2px solid lightgrey", borderRadius: "10px" }}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormControl style={{ width: "60%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                                <TextField
                                                    label="Staff Name"
                                                    select
                                                    value={tripUserId}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => handleUser(e)}
                                                    name="UserId"
                                                    variant="standard"
                                                    disabled={disableUserList}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }} >
                                                    {userList.map(option => (
                                                        <MenuItem key={option.userId} value={option.userId}>
                                                            {option.fullName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormControl style={{ width: "60%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                                <TextField
                                                    label="Role"
                                                    select
                                                    value={role}
                                                    onChange={e => handleRole(e)}
                                                    name="role"
                                                    variant="standard"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}>
                                                    {rolesList.map(option => (
                                                        <MenuItem key={option.roleId} value={option}>
                                                            {option.roleName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                            <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                                style={{
                                                    borderRadius: "50%",
                                                    backgroundColor: "rgb(245, 147, 0)",
                                                    color: "white",
                                                    padding: "5px",
                                                    fontSize: "20px",
                                                    cursor: "pointer"
                                                }} onClick={() => addRoles(values)} />
                                            <Dialog open={openPopup} closeonescape="true" maxWidth="md" >
                                                <DialogContent className="dialogStyle">
                                                    <div className={`${classes.root} materialTableStyle`}>
                                                        Staff already has been entered for this trip. Are you sure you want to enter it again?
                                                    </div>
                                                    <DialogActions>
                                                        <Grid container spacing={4} className="mt-1">
                                                            <Grid item xs={12} sm={12} md={12} className="text-center">
                                                                <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => { setOpenPopup(false); setTripUserId(0) }}>
                                                                    No
                                                                </Button>&nbsp;&nbsp;
                                                                <Button type="button" variant="contained" color="primary" className="noShadow addBtn" onClick={addDuplicateUser}>
                                                                    Yes
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </DialogActions>
                                                </DialogContent>
                                            </Dialog>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <FormControl style={{ width: "60%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                                <TextField
                                                    id="notes"
                                                    label="Notes"
                                                    name="Notes"
                                                    type="text"
                                                    color="primary"
                                                    value={roleNotes}
                                                    onBlur={handleBlur}
                                                    onChange={e => {
                                                        roleNotes = e.target.value
                                                        setRoleNotes(roleNotes)
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    variant="standard"
                                                />
                                            </FormControl>
                                        </Grid>
                                        {displayHoursOrRate(handleChange, values, handleBlur)}
                                    </Grid>
                                    {displayRoles()}
                                    <br></br>
                                </Grid>
                            </Grid>

                            <Grid container spacing={4} className="mt-3">
                                <Grid item xs={12} sm={12} md={12} className="text-right">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/trip/list")}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>

                        </UserWrapper>
                    </form>
                )}
            </Formik>
        </div>
    );
}
