import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/ChatOutlined';
import CodeIcon from '@mui/icons-material/Code';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import GradeTwoTone from '@mui/icons-material/GradeTwoTone';
import ListIcon from '@mui/icons-material/List';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LockOpenIcon from '@mui/icons-material/LockOpenOutlined';
import MailIcon from '@mui/icons-material/MailOutlined';
import PresentToAllIcon from '@mui/icons-material/PresentToAll';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import PersonIcon from '@mui/icons-material/PersonOutlined';
import ReceiptIcon from '@mui/icons-material/ReceiptOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import Domain from '@mui/icons-material/Domain';
import KayakingIcon from '@mui/icons-material/Kayaking';
import GroupsIcon from '@mui/icons-material/Groups';
import PaymentsIcon from '@mui/icons-material/Payments';
import PaidIcon from '@mui/icons-material/Paid';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListIcon: ListIcon,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  PeopleOutlined: PeopleOutlined,
  Domain: Domain,
  AccessTimeIcon: AccessTimeIcon,
  KayakingIcon: KayakingIcon,
  GroupsIcon: GroupsIcon,
  PaymentsIcon: PaymentsIcon,
  PaidIcon: PaidIcon,
  AccessAlarmIcon: AccessAlarmIcon,
  ScheduleIcon: ScheduleIcon,
  AlarmOnIcon: AlarmOnIcon
};

let tokens = localStorage.getItem("loginRes");
tokens = JSON.parse(tokens)
export default [
  {
    label: ' ',
    content: JSON.parse(
      `[
  {
    "label": "Dashboard",
    "icon": "DashboardTwoToneIcon",
    "to": "/dashboard"
  },
  {
    "label": "Staff",
    "icon": "PeopleIcon",
    "to": "/user/list"
  },
  {
    "label": "Roles",
    "icon": "GroupsIcon",
    "to": "/role/list"
  },
  {
    "label": "Trip Summary",
    "icon": "KayakingIcon",
    "to": "/trip/list"
  },
  {
    "label": "Trip Detail",
    "icon": "KayakingIcon",
    "to": "/trip/detail"
  },
  {
    "label": "Staff Earnings",
    "icon": "PaidIcon",
    "to": "/user/earnings"
  },
  {
    "label": "Add Trip",
    "icon": "KayakingIcon",
    "to": "/trip/add"
  },
  {
    "label": "Payroll Lock",
    "icon": "CalendarTodayIcon",
    "to": "/trip/date"
  },
  {
    "label": "Documents",
    "icon": "FolderIcon",
    "to": "/user/files/0"
  },
  {
    "label": "Time Off",
    "icon": "AccessAlarmIcon",
    "to": "/timeOff"
  },
  {
    "label": "Add Trip Schedule",
    "icon": "ScheduleIcon",
    "to": "/add/schedule"
  },
  {
  "label": "Daily Schedule",
  "icon": "AlarmOnIcon",
  "to": "/daliy/schedule/list"
  },
  {
    "label": "Weekly Trip Schedule",
    "icon": "ScheduleIcon",
    "to": "/schedule"
  }
]`,

      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
]
