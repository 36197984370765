import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components';
import { Button, FormControlLabel, FormControl, Grid, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import { Loader } from '../../../shared/components/Loader';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
.css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #F59300 !important;
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    border : 1px solid #F59300 !important;
    color : #F59300 !important;
}
    position: relative;

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
`;

export default function AddRole() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    const { id } = useParams();
    const [loading, setLoading] = React.useState(false);
    const [minutes, setMinutes] = useState([]);
    var [filteredRows, setFilteredRows] = React.useState(
        {
            roleName: "",
            rate: "",
            hours: "",
            isActive: false
        }
    );
    var [role, setRole] = React.useState("");
    const [disableHours, setDisableHours] = useState(false);
    const navigate = useNavigate();
    const roleUpdated = () => toast("Role Updated Successfully!");
    const roleUpdateError = () => toast("An error occured, please try again later!");
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)

    const useEffectOnce = (effect) => {

        const destroyFunc = useRef();
        const effectCalled = useRef(false);
        const renderAfterCalled = useRef(false);
        const [val, setVal] = useState(0);

        if (effectCalled.current) {
            renderAfterCalled.current = true;
        }

        useEffect(() => {

            // only execute the effect first time around
            if (!effectCalled.current) {
                destroyFunc.current = effect();
                effectCalled.current = true;
            }

            // this forces one render after the effect is run
            setVal(val => val + 1);

            return () => {
                // if the comp didn't render since the useEffect was called,
                // we know it's the dummy React cycle
                if (!renderAfterCalled.current) { return; }
                if (destroyFunc.current) { destroyFunc.current(); }
            };
        }, []);
    };


    useEffectOnce(() => {
        setLoading(true);
        setTimeout(() => {
            const RAFTCO_ROLES_URL = config.API_URL + "/api/Roles/" + id
            axios.get(RAFTCO_ROLES_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        filteredRows = res.data.data
                        setFilteredRows(filteredRows)
                        role = res.data.data.roleName
                        setRole(role)
                        if (role.toLowerCase() == "gratuity") {
                            setDisableHours(true)
                        }
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }

                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                }).finally(() => {
                    setLoading(false);
                })
        })
        Hours()

    })



    const minTwoDigits = (n) => {
        return (n < 10 ? '0' : '') + n;
    }

    const Hours = () => {
        for (let index = 0; index < 41; index++) {
            const x = index;
            let min = 0;

            if (x < 13 && x != 0) {
                for (let jindex = 0; jindex < 3; jindex++) {
                    min = min + 15;
                    minutes.push({
                        value: `${minTwoDigits(x - 1)}:${min}:00`,
                        display: `${minTwoDigits(x - 1)}:${min}`
                    })
                    setMinutes(minutes)
                }
            }

            minutes.push({
                value: `${minTwoDigits(x)}:00:00`,
                display: `${minTwoDigits(x)}:00`
            })
            setMinutes(minutes)
        }

    }

    const handleRole = (e, values) => {
        role = e.target.value
        setRole(role)
        if (role === "hours" || role === "Hours") {
            filteredRows.hours = "01:00:00"
            setDisableHours(true)
        } else if (role.toLowerCase() == "gratuity") {
            values.Hours = "00:00:00"
            setDisableHours(true)
        } else {
            setDisableHours(false)
        }
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                enableReinitialize={true}
                initialValues={{ Role: role, Rate: filteredRows.rate, IsActive: filteredRows.isActive, Hours: filteredRows.hours }}

                validate={values => {
                    const errors = {};
                    if (!values.Role) {
                        errors.Role = 'Required!';
                    }
                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}

                onSubmit={(values, { setSubmitting }) => {
                    setLoading(true);
                    setTimeout(() => {
                        const postData = {
                            roleId: id,
                            roleName: role,
                            rate: values.Rate,
                            hours: values.Hours,
                            isActive: values.IsActive
                        }
                        let tokens = localStorage.getItem("loginRes");
                        tokens = JSON.parse(tokens)
                        const RAFTCO_ROLE_URL = config.API_URL + "/api/Roles/add"
                        const token = config.TOKEN
                        axios.post(RAFTCO_ROLE_URL, postData, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {

                                if (res.data.successCode === 1) {
                                    setTimeout(() => { roleUpdated() }, 2000)
                                    // setMessage(
                                    //     <div className="message">
                                    //         <div className="alert alert-success" role="alert">
                                    //             Role updated successfully!
                                    //         </div>
                                    //     </div>)
                                } else {
                                    setTimeout(() => { roleUpdateError() }, 2000)
                                    // setMessage(
                                    //     <div className="message">
                                    //         <div className="alert alert-danger" role="alert">
                                    //             "An error occured,try again later"
                                    //         </div>
                                    //     </div>)
                                }
                                setTimeout(() => { navigate("/role/list") }, 5000)
                            }, 400).catch(err => {
                                if (err.response.status == 401) {
                                    navigate("/login")
                                }
                            }).finally(() => {
                                setLoading(false);
                            })
                        setSubmitting(false)
                    })
                }}

            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (

                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="mt-3">
                                    {message}
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <PageTitle>Edit Role</PageTitle>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="text-right">

                                        </Grid>
                                    </Grid>

                                    <Grid className="mt-3">
                                        <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                                            <TextField
                                                label="Role"
                                                id="role"
                                                name="Role"
                                                type="text"
                                                onChange={e => handleRole(e, values)}
                                                onBlur={handleBlur}
                                                value={values.Role}
                                                variant="standard"
                                            ></TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid className="mt-3">
                                        <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                                            <TextField
                                                label="Rate in dollars"
                                                id="rate"
                                                name="Rate"
                                                type="text"
                                                value={values.Rate}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                variant="standard"
                                            ></TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid className="mt-3">
                                        <FormControl fullWidth className={clsx(classes.withoutLabel, classes.textField)}>
                                            <TextField
                                                label="Hours"
                                                select
                                                value={values.Hours}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name="Hours"
                                                variant="standard"
                                                // InputLabelProps={{
                                                //     shrink: true,

                                                // }}
                                                disabled={disableHours}
                                            >
                                                {minutes.map(option => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.display}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                    <Grid className="mt-3">
                                        <FormControlLabel
                                            label="Active"
                                            control={<Switch
                                                id="isactive"
                                                checked={values.IsActive}
                                                name="IsActive"
                                                color="warning"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                onChange={handleChange}
                                            />} >
                                        </FormControlLabel>
                                    </Grid>

                                    <Grid container spacing={4} className="mt-3">
                                        <Grid item xs={12} sm={12} md={12} className="text-right">
                                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/role/list")}>
                                                Cancel
                                            </Button>&nbsp;&nbsp;
                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )}

            </Formik>
        </div>
    );
}
