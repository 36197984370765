import React, { lazy, Suspense, useState } from 'react';
import { Routes, Route, Navigate, useLocation, Switch, Router } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@mui/styles';

import MuiTheme from './theme';


import { LeftSidebar, PresentationLayout } from './layout-blueprints';

import { Loader } from './shared/components/Loader';
import Dashboard from './pages/Dashboard';
import UserList from './pages/Users/Users/UserList';
import AddUser from './pages/Users/AddUser';
import EditUser from './pages/Users/EditUser';
import Roles from './pages/Roles/Roles/Roles';
import AddRole from './pages/Roles/AddRole';
import EditRole from './pages/Roles/EditRole.js';
import TripList from './pages/Trip/TripList/TripList';
import TripDetail from './pages/Trip/TripDetail/TripDetail';
import AddTrip from './pages/Trip/AddTrip/AddTrip';
import UpdateTrip from './pages/Trip/UpdateTrip';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ForgotPassword/ResetPassword';
import UserTripSummary from './pages/Users/UserTripSummary';
import UserEarnings from './pages/Users/UserEarnings';
import TripDate from './pages/Trip/TripDate/TripDate';
import FileUpload from './pages/Users/FileUpload/FileUpload';
import Files from './pages/Users/FileUpload/Files.js';
import TimeOff from './pages/TimeOff/TimeOff.js';
import Schedule from './pages/TripSchedule/Schedule.js';
import TripSchedule from './pages/scheduleTrip/schedule.js';
import ScheduleList from './pages/DaliySchedule/scheduleList.js';
const Login = lazy(() => import('./pages/Login'));
const RoutesComponent = () => {
  const [location, setLocation] = useState(useLocation())
  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense
          fallback={
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
              <div className="w-50 mx-auto">
                <Loader color="#ff9900" />
              </div>
            </div>
          }>
          <PresentationLayout>
            {/* login */}
          </PresentationLayout>
          <Routes>
            <Route path='/login' element={<Login />} />
            <Route path="/resetpwd/:pwdkey" element={<ForgotPassword />}></Route>
            <Route path="/" element={<Navigate replace to="/login" />} />
            <Route element={<LeftSidebar />}>
              <Route path="/dashboard" element={<Dashboard />}></Route>
              <Route path="/user/list" element={<UserList />}></Route>
              <Route path="/user/add" element={<AddUser />}></Route>
              <Route path="/user/edit/:id" element={<EditUser />}></Route>
              <Route path="/user/reset-password" element={<ResetPassword />}></Route>
              <Route path="/role/list" element={<Roles />}></Route>
              <Route path="/role/add" element={<AddRole />}></Route>
              <Route path="/role/edit/:id" element={<EditRole />}></Route>
              <Route path="/trip/list" element={<TripList />}></Route>
              <Route path="/trip/detail" element={<TripDetail />}></Route>
              <Route path="/trip/add" element={<AddTrip />}></Route>
              <Route path="/trip/date" element={<TripDate />}></Route>
              <Route path="/trip/edit/:id" element={<UpdateTrip />}></Route>
              <Route path="/user/summary/:id" element={<UserTripSummary />}></Route>
              <Route path="/user/earnings" element={<UserEarnings />}></Route>
              <Route path="/user/file/upload" element={<FileUpload />}></Route>
              <Route path="/user/files/:userid" element={<Files />}></Route>
              <Route path="/timeOff" element={<TimeOff />}></Route>
              <Route path="/timeOff/:id" element={<TimeOff />}></Route>
              <Route path="/schedule" element={<Schedule />}></Route>
              <Route path="/add/schedule" element={<TripSchedule />}></Route>
              <Route path="/add/schedule/:id" element={<TripSchedule />}></Route>
              <Route path="/daliy/schedule/list" element={<ScheduleList  />}></Route>
            </Route>
          </Routes>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default RoutesComponent;
