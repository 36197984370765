import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, FormControl, Grid, MenuItem, InputLabel, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import { Loader } from '../../../shared/components/Loader';
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }
    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }

    .cardStyle {
        backgroundColor : blue !important;
    }
    input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
	-webkit-appearance: none;
} 
`;

export default function FileUpload() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    const navigate = useNavigate();
    const fileAdded = () => toast("File Added Successfully!");
    const fileAddError = () => toast("An error occured, please try again later!");
    const [loading, setLoading] = useState(false);
    var [file, setFile] = React.useState([]);
    var [base64URL, setBase64URL] = React.useState([]);
    var [userProfile, setUserProfile] = useState();
    var [userEmail, setUserEmail] = useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {

        userProfile = localStorage.getItem("loginRes");
        userProfile = JSON.parse(userProfile)
        setUserProfile(userProfile)
        if (userProfile?.userType == "User" || userProfile?.userType == "Leader") {
            userEmail = userProfile?.userEmail;
            setUserEmail(userEmail);
        } else {
            userEmail = queryParams.get('useremail');
            setUserEmail(userEmail)
        }
    }, [])


    const getBase64 = file => {
        return new Promise(resolve => {
            let baseURL = [];
            for (var i = 0; i < file.length; i++) {
                let reader = new FileReader();
                reader.readAsDataURL(file[i]);
                reader.onload = () => {
                    baseURL.push(reader.result);
                    resolve(baseURL);
                };
            }
        });
    }

    const handleFileInputChange = event => {
        file = event.target.files;
        getBase64(file).then(result => {
            file["base64"] = result;
            base64URL = result
            setBase64URL(base64URL)
        }).catch(err => {
            console.log(err);
        })
        file = event.target.files[0]
        setFile(file)
    }

    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                initialValues={{ Comments: '' }}
                validate={values => {
                    const errors = {};

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setLoading(true);
                    setTimeout(() => {
                        const postData = {
                            userName: userEmail,
                            fileName: file.name,
                            fileData: file.base64[0],
                            fileType: values.fileType,
                            comments: values.Comments
                        }

                        const RAFTCO_URL = config.API_URL + "/api/user/upload"
                        axios.post(RAFTCO_URL, postData, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {

                                if (res.data.successCode === 1) {
                                    setTimeout(() => { fileAdded() }, 2000)
                                } else {
                                    setTimeout(() => { fileAddError() }, 2000)
                                }
                                setTimeout(() => { navigate("/user/files/0") }, 5000)

                            }, 400).catch(err => {
                                if (err.response.status == 401) {
                                    navigate("/login")
                                }
                            }).finally(() => {
                                setLoading(false);
                            })
                        setSubmitting(false)
                    })
                }}

            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            {message}

                            <Grid container spacing={4} className='d-flex justify-content-center'>
                                <Grid item xs={12} sm={8} md={8} className="mt-3">
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={6} md={6}>
                                            <PageTitle>Document Upload</PageTitle>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={6} className="text-right">

                                        </Grid>
                                    </Grid>


                                    <Grid className="mt-3">
                                        <FormControl className={clsx(classes.withoutLabel, classes.textField)}>
                                            <div>
                                                <input
                                                    id="Files"
                                                    type="file"
                                                    multiple
                                                    accept=".png, .jpg, .jpeg, .pdf"
                                                    color="primary"
                                                    variant="outlined"
                                                    onChange={handleFileInputChange}
                                                /></div>
                                        </FormControl>
                                    </Grid>

                                    <Grid className="mt-3">
                                        <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                            <InputLabel htmlFor="fileType">File Type</InputLabel>
                                            <Select
                                                id="fileType"
                                                name="fileType"
                                                label="File Type"
                                                variant="standard"
                                                value={values.fileType || ''}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            >
                                                <MenuItem value="TD1 Tax Form">TD1 Tax Form</MenuItem>
                                                <MenuItem value="TD1 BC Tax Form">TD1 BC Tax Form</MenuItem>
                                                <MenuItem value="Void cheque">Void cheque</MenuItem>
                                                <MenuItem value="Others">Others</MenuItem>
                                            </Select>
                                            <span className="errMsg">{errors.fileType && touched.fileType && errors.fileType}</span>
                                        </FormControl>
                                    </Grid>

                                    <Grid className="mt-3">
                                        <TextField
                                            label="Comments"
                                            id="comments"
                                            name="Comments"
                                            type="text"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.Comments}
                                            variant="standard"
                                        ></TextField>
                                    </Grid>

                                    <br></br>
                                    <Grid container spacing={4} className="mt-1">
                                        <Grid item xs={12} sm={12} md={12} className="text-right">
                                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => navigate("/user/files/0")}>
                                                Cancel
                                            </Button>&nbsp;&nbsp;
                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </UserWrapper>
                    </form>
                )}
            </Formik>
        </div>
    );
}
