import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from '../config.json';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import PageTitle from '../../shared/components/PageTitle';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import { Formik } from 'formik';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { Select, MenuItem, Box, FormControl, InputLabel, Grid, Button, Autocomplete } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Loader } from '../../shared/components/Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { CoPresentOutlined } from '@mui/icons-material';

const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListVisibilityIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListVisibilityIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .closeIcon { 
            position: absolute;
            left: 450px;
            right: 0px;
            top: 20px;
            bottom: 0px;
        }
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
   
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    },
    greenButton: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'darkgreen',
        },
    },
}));

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'role', numeric: false, label: 'Role' },
    { id: 'user', numeric: false, label: 'User' },
    { id: 'startTime', numeric: false, label: 'Start Time' },
    { id: 'endTime', numeric: false, label: 'End Time' },
    { id: 'isStandby', numeric: false, label: 'Is Standby' }
];

const headCellsListOfResources = [
    { id: 'resourcesId', numeric: false, label: 'Resoures' },
    { id: 'startTime', numeric: false, label: 'Start Time' },
    { id: 'endTime', numeric: false, label: 'End Time' },
    { id: 'isStandby', numeric: false, label: 'Is Standby' }
]

const headCellsListOfShuttle = [
    { id: 'shuttleType', numeric: false, label: 'Shuttle Type' },
    { id: 'role', numeric: false, label: 'Role' },
    { id: 'user', numeric: false, label: 'User' },
    { id: 'startTime', numeric: false, label: 'Start Time' },
    { id: 'endTime', numeric: false, label: 'End Time' },
    { id: 'isStandby', numeric: false, label: 'Is Standy' }
]

const headCellsListOfShuttlePopup = [
    { id: 'shuttleName', numeric: false, label: 'Shuttle Name' },
    { id: 'employee', numeric: false, label: 'User' },
    { id: 'totalCounnt', numeric: false, label: 'Count' }
]

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableHeadResources(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCellsListOfResources.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableHeadShuttle(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCellsListOfShuttle.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableHeadShuttlePopup(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCellsListOfShuttlePopup.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

EnhancedTableHeadResources.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

EnhancedTableHeadShuttle.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

EnhancedTableHeadShuttlePopup.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default function TripSchedule() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [showTable, setShowTable] = React.useState(false);
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens);
    var [loading, setLoading] = React.useState(false);
    var [progress, setProgress] = React.useState(false);
    const [hasClicked, setHasClicked] = React.useState(false);
    const [openPopup, setOpenPopup] = React.useState(false);
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [shuttlePopup, setShuttlePopup] = React.useState(false);
    const [approvePopup, setApprovePopup] = React.useState(false);
    const [userList, setUserList] = React.useState([]);
    const [roleList, setRoleList] = React.useState([]);
    var [tripLeaderId, setTripLeaderId] = React.useState(null);
    var [userId, setUserId] = React.useState(0);
    const [scheduleId, setScheduleId] = React.useState([]);
    const [approved, setApproved] = React.useState(false);
    var [tripNameList, setTripNameList] = React.useState([]);
    var [resourcesList, setResourcesList] = React.useState([]);
    const [resourcesTripItems, setResourcesTripItems] = React.useState([]);
    var [resourcesFilteredRows, setResourcesFilteredRows] = React.useState([]);
    var [shuttleRows, setShuttleRows] = React.useState([{ shuttleType: '', fullName: '', roleName: '', startTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), endTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), isStandby: '' },
    { shuttleType: '', fullName: '', roleName: '', startTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), endTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), isStandby: '' }]);
    const [shuttleFilteredRows, setShuttleFilteredRows] = React.useState([]);
    const [disableLeaderList, setDisableLeaderList] = React.useState(false);
    const [formattedStartTime, setFormattedStartTime] = React.useState(null);
    const [formattedEndTime, setFormattedEndTime] = React.useState(null);
    const [shuttleStartTime, setShuttleStartTime] = React.useState(null);
    const [shuttleEndTime, setShuttleEndTime] = React.useState(null);
    var [location, setLocation] = React.useState("all");
    var [shuttleList, setShuttleList] = React.useState([]);

    const addTripSchedule = () => toast("Trip Schedule Added Successfully!");
    const updateTripSchedule = () => toast("Trip Schedule Updated Successfully!");
    const scheduleApproved = () => toast("Trip Schedule Approved Successfully!");
    const deleteMessage = () => toast("Trip Schedule deleted successfully!");
    const errorMessage = () => toast("An error occured, please try again later!");
    const locationErrorMessage = () => toast("Please select Trip first!");
    const viewShuttleErrorMessage = () => toast("Please select Trip Date");

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const addNewRow = () => {
        setFilteredRows([...filteredRows, { fullName: '', roleName: '', startTime: '', endTime: '', isStandby: '' }]);
    };

    const removeRow = (index) => {
        setFilteredRows(filteredRows.filter((_, i) => i !== index));
    };

    const addNewResourcesListRow = () => {
        setResourcesFilteredRows([...resourcesFilteredRows, { resourceName: '', resourcesStartTime: '', resourcesEndTime: '', isStandby: '' }])
    }

    const removeResourcesListRow = (index) => {
        setResourcesFilteredRows(resourcesFilteredRows.filter((_, i) => i !== index));
    }

    const addNewShuttleRows = () => {
        setShuttleRows([...shuttleRows, { shuttleType: '', fullName: '', roleName: '', shuttleStartTime: '', shuttleEndTime: '' }])
    }

    const removeShuttleRows = (index) => {
        setShuttleRows(shuttleRows.filter((_, i) => i !== index));
    }

    useEffect(() => {
        TripNameList();
        UsersList();
        RoleList();
        ResourcesList();

        if (id) {
            const RAFTCO_SCHEDULE_URL = config.API_URL + "/Schedule/api/Schedule/" + id
            axios.get(RAFTCO_SCHEDULE_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        rows = res.data.data
                        setRows(rows);
                        const location = rows.location;
                        setLocation(location);
                        const templateId = rows.tripId
                        handleLocation(location, templateId)
                        const approved = rows.isApproved;
                        setApproved(approved)
                        const initialTripItems = rows.tripItems.map((item) => {
                            return {
                                ...item
                                // fullName: userList.find(user => user.userId === item.userId)?.fullName,
                                // roleName: roleList.find(role => role.roleId === item.roleId)?.roleName,
                                // resourceName: resourcesList.find(resource => resource.resourceId === item.resourceId)?.resourceName,
                                // startTime: dayjs(item.startTime),
                                // endTime: dayjs(item.endTime)
                                // isStandby: item.isStandby
                            };
                        });
                        let shuttleRows = [];

                        initialTripItems.forEach((item) => {
                            if (item.shuttleTypeId > 0) {
                                shuttleRows.push(item)
                            }
                            else if (item.userId > 0 && item.shuttleTypeId == -1) {
                                filteredRows.push(item);
                            } else {
                                resourcesFilteredRows.push(item);
                            }
                        });

                        setFilteredRows(filteredRows);
                        setResourcesFilteredRows(resourcesFilteredRows);
                        setShuttleRows(shuttleRows);

                        // to remove the default 2 rows which is usestate at the top
                        // if (shuttleRows.length > 0) {
                        //     shuttleRows = shuttleRows.slice(-2)
                        //     setShuttleRows(shuttleRows)
                        // }
                        if (shuttleRows.length === 0) {
                            shuttleRows = [
                                { shuttleType: '', fullName: '', roleName: '', startTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), endTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), isStandby: '' },
                                { shuttleType: '', fullName: '', roleName: '', startTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), endTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), isStandby: '' }
                            ];
                        }
                        setShuttleRows(shuttleRows);
                        const resourcesTripItems = rows.tripItems.map((items) => {
                            return {
                                ...items,
                                resourceName: resourcesList.find(resource => resource.resourceId === items.resourceId)?.resourceName || "",
                                resourcesStartTime: dayjs(items.startTime),
                                resourcesEndTime: dayjs(items.endTime)
                            };
                        });
                        setResourcesTripItems(resourcesTripItems);
                        setShowTable(true);
                        setHasClicked(true);
                    } else {
                        errorMessage();
                    }
                })
        }
    }, [id])

    const TripNameList = () => {
        const RAFTCO_USER_URL = config.API_URL + "/Trip/api/Trip/nameList"
        axios.get(RAFTCO_USER_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    const tripName = res.data.data
                    setTripNameList(tripName);
                } else {
                    errorMessage();
                }
            })
    }

    const UsersList = () => {
        const RAFTCO_USER_URL = config.API_URL + "/api/Users"
        axios.get(RAFTCO_USER_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    let users = res.data.data
                    users = users.filter(u => u.isActive == true)
                    const userList = users
                    setUserList(userList);

                    tripLeaderId = tokens.id
                    setTripLeaderId(tripLeaderId);

                    if (tokens.userType == 'Admin') {
                        setDisableLeaderList(true)
                    }
                    if (tokens.userType == 'User') {
                        userId = tokens.id
                        setUserId(userId);
                        setDisableLeaderList(true);
                    }

                } else {
                    errorMessage();
                }
            })
    }

    const RoleList = () => {
        const RAFTCO_USER_URL = config.API_URL + "/api/Roles"
        axios.get(RAFTCO_USER_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    const roles = res.data.data;
                    setRoleList(roles);
                } else {
                    errorMessage();
                }
            })
    }

    const ResourcesList = () => {
        setLoading(true);
        setTimeout(() => {
            const RAFTCO_RESOURCES_URL = config.API_URL + '/api/Users/resources'
            axios.get(RAFTCO_RESOURCES_URL, {
                headers: {
                    'AUthorization': `Bearer ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        const resources = res.data.data
                        setResourcesList(resources);
                    } else {
                        errorMessage();
                    }
                }).finally(() => {
                    setLoading(false);
                });
        }, 1000);
    }

    const generateNumbers = () => {
        const numbers = [];
        for (let i = 1; i <= 200; i++) {
            numbers.push({
                value: i,
                display: `${i}`
            })
        }
        return numbers;
    }

    const handleChecked = (e, index) => {
        const updatedRows = [...filteredRows]
        updatedRows[index].isStandby = e.target.checked
        setFilteredRows(updatedRows);
    }

    const handleShuttleChecked = (e, index) => {
        const updatedRows = [...shuttleRows]
        updatedRows[index].isStandby = e.target.checked
        setShuttleFilteredRows(updatedRows);
    }

    const handleIsStandbyChecked = (e, index) => {
        const updatedRows = [...resourcesFilteredRows]
        updatedRows[index].isStandby = e.target.checked
        setResourcesFilteredRows(updatedRows);
    }

    const calculateEndTime = (startTime, totalTime, tripDate) => {
        const [hours, minutes] = startTime.split(':').map(Number);

        // const startDate = new Date(tripDate);
        const startDate = new Date(tripDate + 'T' + startTime + ':00');
        startDate.setHours(hours, minutes, 0);

        const endDate = new Date(startDate.getTime() + totalTime * 60000);

        const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const formattedStartTime = startDate.toLocaleString('en-US', options).replace(',', '');
        const formattedEndTime = endDate.toLocaleString('en-US', options).replace(',', '');

        return { formattedStartTime, formattedEndTime };
    };

    const handleOpenPopup = () => {
        const openPopup = true
        setOpenPopup(openPopup);
    }

    const handleAprrovePopup = () => {
        const approvePopup = true
        setApprovePopup(approvePopup);
    }

    const openDeletePopup = (rows) => {
        const scheduleId = rows.scheduleId
        setScheduleId(scheduleId);
        const deletePopup = true
        setDeletePopup(deletePopup);
    }

    const deleteSchedule = () => {
        setLoading(true);
        setTimeout(() => {
            const RAFTCO_DELETE_URL = config.API_URL + "/schedule/api/schedule/delete/" + scheduleId
            axios.delete(RAFTCO_DELETE_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        setTimeout(() => { deleteMessage(); }, 2000)
                    } else {
                        errorMessage();
                    }
                    setTimeout(() => { navigate("/schedule") }, 5000)
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                    setDeletePopup(false);
                }).finally(() => {
                    setLoading(false);
                    setDeletePopup(false);
                })
        })
    }

    const handleLocation = (value, templateId) => {

        if (!templateId) {
            locationErrorMessage()
        } else {
            location = value
            setLocation(location)

            const RAFTCO_SCHEDULE_URL = config.API_URL + "/Schedule/api/Schedule/shuttletypes/" + value + "/" + templateId
            axios.get(RAFTCO_SCHEDULE_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        shuttleList = res.data.data
                        setShuttleList(shuttleList)
                    };
                });
        }
    }


    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    return (
        <div>
            <ToastContainer theme="dark" />
            <Dialog open={shuttlePopup} onClose={() => setShuttlePopup(false)} closeonescape="true" fullWidth={true} maxWidth="sm">
                <TableWrapper>
                    <DialogContent>
                        <div className="text-right" onClick={() => setShuttlePopup(false)}>
                            <FontAwesomeIcon icon={faWindowClose} className="closeIcon" />
                        </div>
                        <TableContainer>
                            <Table
                                className="materialTableStyle"
                                aria-labelledby="tableTitle"
                                aria-label="enhanced table"
                            >
                                <EnhancedTableHeadShuttlePopup
                                    classes={classes}
                                    order={order}
                                    orderBy={orderBy}
                                    onRequestSort={handleRequestSort}
                                />
                                <TableBody>
                                    {stableSort(shuttleFilteredRows, getComparator(order, orderBy))
                                        .map((shuttleFilteredRows, index) => {
                                            return (
                                                <TableRow
                                                    tabIndex={-1}
                                                    key={shuttleFilteredRows.shuttleId}
                                                >
                                                    <TableCell>{shuttleFilteredRows.shuttleName}</TableCell>
                                                    <TableCell>{shuttleFilteredRows.employee}</TableCell>
                                                    <TableCell>{shuttleFilteredRows.totalCounnt}</TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Grid item xs={12} sm={12} md={12} className="text-center">
                            <Button variant="outlined" color="primary" className="noShadow cancelBtn " onClick={() => setShuttlePopup(false)}>
                                Cancel
                            </Button>
                        </Grid>
                    </DialogActions>
                </TableWrapper>
            </Dialog>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    TemplateId: rows?.tripId,
                    TripDate: rows?.tripDate ? dayjs(rows.tripDate).format('YYYY-MM-DD') : null,
                    ClientName: rows?.clientName,
                    PhoneNumber: rows?.phoneNumber,
                    Notes: rows?.notes,
                    ClientCount: rows?.clientCount,
                    OpenTo: rows?.openTo,
                    Shuttle: rows?.shuttle,
                    Location: rows?.location,
                    TripName: rows?.tripId ? tripNameList.find(trip => trip.templateId === rows?.tripId)?.tripName : ''
                }}
                validate={values => {

                    const errors = {};
                    if (!values.TemplateId) {
                        errors.TemplateId = 'Required!';
                    }

                    if (!values.TripDate) {
                        errors.TripDate = 'Required';
                    }

                    if (!values.ClientCount) {
                        errors.ClientCount = 'Required!';
                    }

                    const filteredRowsErrors = filteredRows.map(row => {
                        const rowErrors = {};
                        if (!row.userId) { rowErrors.userId = 'Required!' };
                        if (!row.roleId) { rowErrors.roleId = 'Required!' };
                        return rowErrors;
                    });

                    if (filteredRowsErrors.some(error => Object.keys(error).length > 0)) {
                        errors.filteredRows = filteredRowsErrors;
                    }

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}

                onSubmit={(values, { setSubmitting }) => {
                    loading = true
                    setLoading(loading);
                    setTimeout(() => {
                        let tripItemsUsers = filteredRows.map(row => ({
                            userId: row.userId,
                            roleId: row.roleId,
                            resourceId: -1,
                            shuttleTypeId: -1,
                            startTime: row.startTime ? dayjs(row.startTime).format('YYYY-MM-DDTHH:mm:ss') : dayjs(formattedStartTime).format('YYYY-MM-DDTHH:mm:ss'),
                            endTime: row.endTime ? dayjs(row.endTime).format('YYYY-MM-DDTHH:mm:ss') : dayjs(formattedEndTime).format('YYYY-MM-DDTHH:mm:ss'),
                            isStandby: row.isStandby || false
                        }))
                        let tripItemsResources = resourcesFilteredRows.map(r => ({
                            userId: -1,
                            roleId: -1,
                            resourceId: r.resourceId,
                            shuttleTypeId: -1,
                            startTime: dayjs(r.startTime).format('YYYY-MM-DDTHH:mm:ss'),
                            endTime: dayjs(r.endTime).format('YYYY-MM-DDTHH:mm:ss'),
                            isStandby: r.isStandby || false
                        }))
                        let tripItemsShuttle = shuttleRows.map(row => ({
                            userId: row.userId,
                            roleId: row.roleId,
                            shuttleTypeId: row.shuttleTypeId,
                            resourceId: -1,
                            startTime: row.startTime ? dayjs(row.startTime).format('YYYY-MM-DDTHH:mm:ss') : dayjs(shuttleStartTime).format('YYYY-MM-DDTHH:mm:ss'),
                            endTime: row.endTime ? dayjs(row.endTime).format('YYYY-MM-DDTHH:mm:ss') : dayjs(shuttleEndTime).format('YYYY-MM-DDTHH:mm:ss'),
                            isStandby: row.isStandby || false
                        }))

                        let combinedTripItems = [...tripItemsUsers, ...tripItemsResources, ...tripItemsShuttle];
                        const postData = {
                            scheduleId: id ? id : 0,
                            tripId: values.TemplateId,
                            tripName: values.TripName === 'Custom' ? values.customTripName : values.TripName,
                            clientName: "",
                            clientCount: values.ClientCount,
                            openTo: values.OpenTo,
                            shuttle: values.Shuttle,
                            phoneNumber: "",
                            notes: values.Notes,
                            tripDate: values.TripDate,
                            tripItems: combinedTripItems,
                            location: location
                        }
                        let tokens = localStorage.getItem("loginRes");
                        tokens = JSON.parse(tokens);
                        const RAFTC0_TRIPSCHEDULE_URL = config.API_URL + "/schedule/api/schedule"
                        axios.post(RAFTC0_TRIPSCHEDULE_URL, postData, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {
                                if (res.data.successCode === 1) {
                                    setTimeout(() => {
                                        { id ? updateTripSchedule() : addTripSchedule() }
                                    }, 2000)
                                }
                                else {
                                    setTimeout(() => { errorMessage(); }, 2000)
                                }
                                setTimeout(() => { navigate("/schedule") }, 5000)
                            }, 400).catch(err => {
                                if (err.response.status == 401) {
                                    navigate("/login")
                                }
                            }).finally(() => {
                                loading = false;
                                setLoading(loading);
                            });
                    })
                    setSubmitting(false)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setValues,
                    setFieldValue,
                    isSubmitting,
                }) => {

                    const isCustomTrip = values.TemplateId === 6;

                    const getSmartAssist = () => {
                        setOpenPopup(false);
                        setProgress(true);
                        const updatedRows = [
                            { shuttleType: '', fullName: '', roleName: '', startTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), endTime: dayjs().format('YYYY-MM-DDTHH:mm:ss') },
                            { shuttleType: '', fullName: '', roleName: '', startTime: dayjs().format('YYYY-MM-DDTHH:mm:ss'), endTime: dayjs().format('YYYY-MM-DDTHH:mm:ss') }
                        ];

                        setShuttleRows(updatedRows);
                        const RAFTCO_TRIP_SCHEDULE = config.API_URL + "/schedule/api/smartassist"
                        const postData = {
                            tripDate: values.TripDate,
                            clientCount: values.ClientCount,
                            templateId: values.TemplateId,
                            openTo: values.OpenTo
                        };
                        axios.post(RAFTCO_TRIP_SCHEDULE, postData, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {
                                if (res.data.successCode === 1) {
                                    const filteredRows = res.data.data.map(row => {
                                        const user = userList.find(user => user.userId === row.userId);
                                        const role = roleList.find(role => role.roleId === row.roleId);
                                        const selectedTrip = tripNameList.find(trip => trip.templateId === values.TemplateId);
                                        const { startTime, totalTime } = selectedTrip || {};
                                        let formattedStartTime = "";
                                        let formattedEndTime = "";

                                        if (startTime && totalTime && values.TripDate) {
                                            const timeCalculation = calculateEndTime(startTime, totalTime, values.TripDate);
                                            formattedStartTime = timeCalculation.formattedStartTime;
                                            formattedEndTime = timeCalculation.formattedEndTime;
                                        }
                                        return {
                                            ...row,
                                            fullName: user ? user.fullName : "",
                                            roleName: role ? role.roleName : "",
                                            startTime: formattedStartTime,
                                            endTime: formattedEndTime,
                                            isStandby: row.standby
                                        };
                                    });
                                    setFilteredRows(filteredRows);
                                    setResourcesFilteredRows([]);
                                    handleLocation(location, values.TemplateId)
                                    setShowTable(true);
                                } else {
                                    errorMessage();
                                }
                            })
                            .finally(() => {
                                setProgress(false);
                            })
                    }

                    const secondClick = () => {
                        if (!hasClicked) {

                            getSmartAssist();
                            setHasClicked(true);
                        } else {
                            setOpenPopup(true);
                        }
                    }

                    const scheduleApproval = () => {
                        setApprovePopup(false);
                        setLoading(true);
                        setTimeout(() => {
                            const postData = {
                                scheduleId: rows.scheduleId,
                                userName: tokens.userEmail
                            };
                            const RAFTCO_TRIP_SCHEDULE = config.API_URL + "/schedule/api/schedule/approval"
                            axios.post(RAFTCO_TRIP_SCHEDULE, postData, {
                                headers: {
                                    'Authorization': `Basic ${tokens.jwtToken}`
                                }
                            })
                                .then((res) => {
                                    if (res.data.successCode === 1) {
                                        setTimeout(() => { scheduleApproved(); }, 500)
                                    } else {
                                        errorMessage();
                                    }
                                    setTimeout(() => { navigate("/schedule") }, 5000)
                                    // navigate("/schedule")
                                }, 400).catch(err => {
                                    if (err.response.status == 401) {
                                        navigate("/login")
                                    }
                                    // setApprovePopup(false);
                                }).finally(() => {
                                    setLoading(false);
                                })
                        })
                    }

                    const viewShuttle = () => {
                        if (!values.TripDate) {
                            viewShuttleErrorMessage()
                        } else {
                            setShuttlePopup(true);
                            const RAFTCO_TRIP_SCHEDULE = config.API_URL + "/schedule/api/schedule/shuttleallocation/" + values.TripDate.split('-').join('')
                            axios.get(RAFTCO_TRIP_SCHEDULE, {
                                headers: {
                                    'Authorization': `Basic ${tokens.jwtToken}`
                                }
                            })
                                .then((res) => {
                                    if (res.data.successCode === 1) {
                                        const shuttleFilteredRows = res.data.data
                                        setShuttleFilteredRows(shuttleFilteredRows);
                                    } else {
                                        errorMessage();
                                    }
                                })
                        }
                    }


                    return (
                        <form onSubmit={handleSubmit}>
                            <TableWrapper>
                                <Grid container spacing={4}>
                                    <Grid item xs={12} sm={6} md={6} className="mb-3">
                                        <PageTitle>Trip Schedule</PageTitle>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="text-right">
                                        {id && (<Button type="button" variant="contained" color="primary" className="noShadow btn-default addBtn" disabled={approved === true} onClick={() => { openDeletePopup(rows) }}>
                                            Delete
                                        </Button>)} &nbsp;
                                        {id && (<Button type="button" variant="contained" className="noShadow btn-default addBtn" disabled={approved === true} onClick={handleAprrovePopup}>
                                            Approve
                                        </Button>)} &nbsp;
                                        <Button type="button" variant="contained" className="noShadow btn-default addBtn" onClick={viewShuttle}>
                                            View Shuttle
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} className="text-right">

                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <FormControl style={{ width: "80%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                            <TextField
                                                className="filterDropdown"
                                                id="tripName"
                                                select
                                                label="Select Trip"
                                                helperText=""
                                                variant="standard"
                                                name="TripName"
                                                value={values.TemplateId ? tripNameList.find(trip => trip.templateId === values.TemplateId)?.tripName : ""}
                                                onChange={(e) =>
                                                    setValues({
                                                        ...values,
                                                        TripName: e.target.value,
                                                        TemplateId: tripNameList.find(trip => trip.tripName === e.target.value)?.templateId
                                                    }
                                                    )}
                                                onBlur={handleBlur}
                                            >
                                                {tripNameList.map(option => (
                                                    <MenuItem key={option.templateId} value={option.tripName}>
                                                        {option.tripName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <div className="errMsg" style={{ marginTop: '5px' }}>{errors.TemplateId && touched.TemplateId && errors.TemplateId}</div>
                                        </FormControl>
                                    </Grid>
                                    {isCustomTrip && (
                                        <Grid item xs={12} sm={6} md={6}>
                                            <TextField style={{ width: "80%" }} label="Custom Trip Name" name="customTripName" variant="standard" value={values.customTripName || ""} onChange={handleChange} onBlur={handleBlur} />
                                        </Grid>
                                    )}
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField
                                            style={{ width: "80%" }}
                                            label="Trip Date"
                                            variant="standard"
                                            type="date"
                                            name="TripDate"
                                            value={values.TripDate || ""}
                                            onChange={(e) => {
                                                const newDate = e.target.value;
                                                setValues({
                                                    ...values,
                                                    TripDate: newDate
                                                })
                                                const selectedTrip = tripNameList.find(trip => trip.tripName === values.TripName);
                                                const { startTime, totalTime } = selectedTrip || {};
                                                if (startTime && totalTime) {
                                                    const { formattedStartTime, formattedEndTime } = calculateEndTime(startTime, totalTime, newDate);

                                                    setValues({
                                                        ...values,
                                                        TripDate: newDate,
                                                        StartTime: formattedStartTime,
                                                        EndTime: formattedEndTime
                                                    });
                                                    setFormattedStartTime(formattedStartTime);
                                                    setFormattedEndTime(formattedEndTime);
                                                    setFilteredRows((prevRows) => prevRows.map((row, index) => ({
                                                        ...row,
                                                        startTime: formattedStartTime,
                                                        endTime: formattedEndTime
                                                    })));

                                                } else if (isCustomTrip) {
                                                    const { formattedStartTime, formattedEndTime } = calculateEndTime(startTime, totalTime, newDate);

                                                    setValues({
                                                        ...values,
                                                        TripDate: newDate,
                                                        StartTime: formattedStartTime,
                                                        EndTime: formattedEndTime
                                                    });
                                                    setFormattedStartTime(formattedStartTime);
                                                    setFormattedEndTime(formattedEndTime);
                                                    setFilteredRows((prevRows) => prevRows.map((row, index) => ({
                                                        ...row,
                                                        startTime: formattedStartTime,
                                                        endTime: formattedEndTime
                                                    })));
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            InputLabelProps={{ shrink: true }}
                                        />

                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.TripDate && touched.TripDate && errors.TripDate}</div>
                                    </Grid>
                                    <Grid item sx={12} sm={6} md={6}>
                                        <FormControl style={{ width: "80%" }}>
                                            <Autocomplete
                                                options={generateNumbers()}
                                                getOptionLabel={(option) => option.display}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                onChange={(event, newValue) => {
                                                    setValues({
                                                        ...values,
                                                        ClientCount: newValue ? newValue.value : "",
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                                value={generateNumbers().find(option => option.value === values.ClientCount) || null}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Client Count"
                                                        variant="standard"
                                                        helperText=""
                                                    />
                                                )}
                                            />
                                            <div className="errMsg" style={{ marginTop: '5px' }}>{errors.ClientCount && touched.ClientCount && errors.ClientCount}</div>
                                        </FormControl>
                                    </Grid>
                                    <Grid item sx={12} sm={6} md={6}>
                                        <FormControl style={{ width: "80%" }}>
                                            <Autocomplete
                                                options={generateNumbers()}
                                                getOptionLabel={(option) => option.display}
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                onChange={(event, newValue) => {
                                                    setValues({
                                                        ...values,
                                                        OpenTo: newValue ? newValue.value : "",
                                                    });
                                                }}
                                                onBlur={handleBlur}
                                                value={generateNumbers().find(option => option.value === values.OpenTo) || null}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Open To"
                                                        variant="standard"
                                                        helperText=""
                                                    />
                                                )}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField style={{ width: "80%" }} id="shuttle" label="Shuttle" name="Shuttle" type="number" variant="standard" value={values.Shuttle || ""} onChange={handleChange} onBlur={handleBlur} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6}>
                                        <TextField style={{ width: "80%" }} label="Trip Notes" name="Notes" variant="standard" value={values.Notes || ""} onChange={handleChange} onBlur={handleBlur} />
                                    </Grid>
                                </Grid>

                                <div className={` ${classes.root} materialTableStyle`}>
                                    <Grid container spacing={4} style={{ marginTop: "-10px" }}>
                                        <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right', paddingRight: '15px' }}>
                                            <Button variant="contained" color="primary" className="noShadow w-40 addBtn " onClick={secondClick} disabled={progress}>
                                                {progress ? <CircularProgress size="25px" color='inherit' /> : "Get Assist"}
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    {showTable &&
                                        <>
                                            <TableContainer>
                                                <Dialog open={openPopup} closeonescape="true" maxWidth="true" >
                                                    <TableWrapper>
                                                        <DialogContent className="dialogStyle">
                                                            <div className={`${classes.root} materialTableStyle`}>
                                                                Do you want to reset user List?
                                                            </div>
                                                            <DialogActions>
                                                                <Grid container spacing={4} className="mt-1">
                                                                    <Grid item xs={12} sm={12} md={12} className="text-center">
                                                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setOpenPopup(false)}>
                                                                            No
                                                                        </Button>&nbsp;&nbsp;
                                                                        <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={getSmartAssist}>
                                                                            Yes
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </DialogActions>
                                                        </DialogContent>
                                                    </TableWrapper>
                                                </Dialog>

                                                <Dialog open={deletePopup} closeonescape="true" maxWidth="true" >
                                                    <TableWrapper>
                                                        <DialogContent className="dialogStyle">
                                                            <div className={`${classes.root} materialTableStyle`}>
                                                                Do you want to delete the record?
                                                            </div>
                                                            <DialogActions>
                                                                <Grid container spacing={4} className="mt-1">
                                                                    <Grid item xs={12} sm={12} md={12} className="text-center">
                                                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setDeletePopup(false)}>
                                                                            No
                                                                        </Button>&nbsp;&nbsp;
                                                                        <Button type="button" variant="contained" color="primary" className="noShadow addBtn" onClick={() => deleteSchedule()}>
                                                                            Yes
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </DialogActions>
                                                        </DialogContent>
                                                    </TableWrapper>
                                                </Dialog>

                                                <Dialog open={approvePopup} closeonescape="true" maxWidth="true" >
                                                    <TableWrapper>
                                                        <DialogContent className="dialogStyle">
                                                            <div className={`${classes.root} materialTableStyle`}>
                                                                Do you want to approve the trip schedule?
                                                            </div>
                                                            <DialogActions>
                                                                <Grid container spacing={4} className="mt-1">
                                                                    <Grid item xs={12} sm={12} md={12} className="text-center">
                                                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setApprovePopup(false)}>
                                                                            No
                                                                        </Button>&nbsp;&nbsp;
                                                                        <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => scheduleApproval()}>
                                                                            Yes
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </DialogActions>
                                                        </DialogContent>
                                                    </TableWrapper>
                                                </Dialog>

                                                <Grid className="d-flex justify-content-between align-items-center" style={{ marginTop: '15px' }}>
                                                    <div className="d-flex justify-content-center flex-grow-1">
                                                        <PageTitle>List of Users</PageTitle>
                                                    </div>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <FontAwesomeIcon
                                                            icon={faAdd}
                                                            className="closeIcon m-3"
                                                            style={{
                                                                borderRadius: "50%",
                                                                backgroundColor: "rgb(245, 147, 0)",
                                                                color: "white",
                                                                padding: "5px",
                                                                fontSize: "20px",
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={addNewRow}
                                                        />
                                                    </Grid>
                                                </Grid>

                                                <Table
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    aria-label="enhanced table"
                                                >
                                                    <EnhancedTableHead
                                                        classes={classes}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort} />
                                                    <TableBody>

                                                        {filteredRows.map((row, index) => (
                                                            < TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={filteredRows.id}
                                                            >
                                                                <TableCell align="left" style={{ width: '20%' }}>
                                                                    <TextField
                                                                        style={{ width: "90%" }}
                                                                        className="filterDropdown"
                                                                        id="roleName"
                                                                        select
                                                                        label="Role"
                                                                        helperText=""
                                                                        variant="standard"
                                                                        name={`filteredRows[${index}].roleId`}
                                                                        // value={filteredRows[index]?.roleName}
                                                                        value={row.roleId}
                                                                        onChange={e => {
                                                                            const updatedRows = [...filteredRows];
                                                                            updatedRows[index].roleId = e.target.value;
                                                                            // updatedRows[index].roleName = e.target.value;
                                                                            // updatedRows[index].roleId = roleList.find(role => role.roleName === e.target.value)?.roleId;
                                                                            setFilteredRows(updatedRows);
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: `filteredRows[${index}].roleName`
                                                                                }
                                                                            });
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        {roleList.map(option => (
                                                                            <MenuItem key={option.roleId} value={option.roleId}>
                                                                                {option.roleName}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                    {errors.filteredRows?.[index]?.roleId && (
                                                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.filteredRows[index].roleId}</div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '20%' }}>
                                                                    {/*   <TextField
                                                                        style={{ width: "80%" }}
                                                                        className="filterDropdown"
                                                                        id="user"
                                                                        select
                                                                        label="User"
                                                                        helperText=""
                                                                        variant="standard"
                                                                        name={`shuttleRows[${index}].userId`}
                                                                        value={row.userId}
                                                                        onChange={(e) => {
                                                                            const updatedRows = [...filteredRows];
                                                                            updatedRows[index].userId = e.target.value;
                                                                            setFilteredRows(updatedRows);
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: `shuttleRows[${index}].fullName`
                                                                                }
                                                                            });
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        {userList.map(option => (
                                                                            <MenuItem key={option.userId} value={option.userId}>
                                                                                {option.fullName}
                                                                            </MenuItem>
                                                                        ))
                                                                        }

                                                                    </TextField>  */}
                                                                    <Autocomplete
                                                                        style={{ width: "90%" }}
                                                                        className="filterDropdown"
                                                                        id="user"
                                                                        options={userList}
                                                                        getOptionLabel={(option) => option.fullName}
                                                                        value={userList.find((user) => user.userId === row.userId) || null}  // Handle undefined or null values
                                                                        onChange={(e, newValue) => {
                                                                            const updatedRows = [...filteredRows];
                                                                            updatedRows[index].userId = newValue ? newValue.userId : null;  // Set userId or null
                                                                            setFilteredRows(updatedRows);  // Update state with new userId

                                                                            handleBlur({
                                                                                target: {
                                                                                    name: `filteredRows[${index}].fullName`,  // Trigger the blur event to update fullName
                                                                                },
                                                                            });
                                                                        }}
                                                                        onBlur={handleBlur}  // Handle the blur event
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                fullWidth
                                                                                label="User"  // Label for the text field
                                                                                variant="standard"
                                                                                helperText=""  // Empty helper text
                                                                            />
                                                                        )}
                                                                    />

                                                                    {errors.filteredRows?.[index]?.userId && (
                                                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.filteredRows[index].userId}</div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '20%' }}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DateTimePicker']}>
                                                                            <DateTimePicker label="Start Date and Time"
                                                                                onChange={(e) => {
                                                                                    const updatedRows = [...filteredRows];
                                                                                    updatedRows[index].startTime = e;
                                                                                    setFilteredRows(updatedRows);
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: `startTime-${index}`
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                value={row.startTime ? dayjs(row.startTime) : dayjs(formattedStartTime)}
                                                                                onBlur={handleBlur}
                                                                                slotProps={{
                                                                                    textField: {
                                                                                        style: {
                                                                                            width: '100%',
                                                                                            paddingRight: '28px',
                                                                                            paddingLeft: '0px'
                                                                                        }
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '20%' }}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DateTimePicker']}>
                                                                            <DateTimePicker label="End Date and Time"
                                                                                onChange={(e) => {
                                                                                    const updatedRows = [...filteredRows];
                                                                                    updatedRows[index].endTime = e;
                                                                                    setFilteredRows(updatedRows);
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: `endTime-${index}`
                                                                                        }
                                                                                    })
                                                                                }}
                                                                                value={row.endTime ? dayjs(row.endTime) : dayjs(formattedEndTime)}
                                                                                onBlur={handleBlur}
                                                                                slotProps={{ textField: { style: { width: '100%', paddingRight: '28px', } } }}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </TableCell>
                                                                <TableCell align="center" style={{ width: '10%' }}>
                                                                    <FormControlLabel control={
                                                                        <Checkbox
                                                                            color="warning"
                                                                            // checked={filteredRows[index]?.isStandBy}
                                                                            checked={row.isStandby}
                                                                            onChange={(e) => handleChecked(e, index)}
                                                                        />}
                                                                        // label="StandBy"
                                                                        style={{ marginTop: '15px', fontSize: '10px' }} />
                                                                    <div className="actionListDeleteIconPosition">
                                                                        <Tooltip title="delete" aria-label="delete">
                                                                            <IconButton className="actionBtn" aria-label="delete" onClick={() => removeRow(index)}>
                                                                                <DeleteIcon className="actionIcon" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}

                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <TableContainer>
                                                <Grid className="d-flex justify-content-between align-items-center" style={{ marginTop: '15px' }}>
                                                    <div className="d-flex justify-content-center flex-grow-1">
                                                        <PageTitle>List of Shuttle</PageTitle>
                                                    </div>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <FontAwesomeIcon
                                                            icon={faAdd}
                                                            className="closeIcon m-3"
                                                            style={{
                                                                borderRadius: "50%",
                                                                backgroundColor: "rgb(245, 147, 0)",
                                                                color: "white",
                                                                padding: "5px",
                                                                fontSize: "20px",
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={addNewShuttleRows}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Table
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    aria-label="enhanced table"
                                                >
                                                    <EnhancedTableHeadShuttle
                                                        classes={classes}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort} />
                                                    <TableBody>

                                                        {shuttleRows.map((row, index) => (
                                                            < TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={rows.shuttleTypeId}
                                                            >
                                                                <TableCell align="left" style={{ width: '38%' }}>
                                                                    <TextField
                                                                        style={{ width: "100%", paddingRight: '15px', }}
                                                                        className="filterDropdown"
                                                                        id="shuttletype"
                                                                        select
                                                                        label="Shuttle Type"
                                                                        helperText=""
                                                                        variant="standard"
                                                                        name={`shuttleRows[${index}].shuttleType`}
                                                                        value={row.shuttleTypeId || 0}
                                                                        onChange={e => {
                                                                            const updatedRows = [...shuttleRows];
                                                                            updatedRows[index].shuttleTypeId = e.target.value;
                                                                            const selectedSchedule = shuttleList.find(shuttle => shuttle.shuttleId === e.target.value);
                                                                            updatedRows[index].shuttleType = selectedSchedule.shuttleName;
                                                                            setShuttleRows(updatedRows);
                                                                            const { startTime, totalMinutes } = selectedSchedule || {};
                                                                            if (startTime && totalMinutes) {
                                                                                const { formattedStartTime, formattedEndTime } = calculateEndTime(startTime, totalMinutes, values.TripDate);
                                                                                updatedRows[index].startTime = formattedStartTime;
                                                                                updatedRows[index].endTime = formattedEndTime;
                                                                                setValues({
                                                                                    ...values,
                                                                                    StartTime: formattedStartTime,
                                                                                    EndTime: formattedEndTime
                                                                                });
                                                                                setShuttleStartTime(updatedRows);
                                                                                setShuttleEndTime(updatedRows);
                                                                                if (id) {
                                                                                    console.log("it is edit page no need of whistler logic")
                                                                                } else {
                                                                                    if (location == "Whistler") {
                                                                                        if (shuttleRows[0].shuttleType == "AM Combined Whistler Pickup") {
                                                                                            if (values.TripName == "AM Elaho") {
                                                                                                var selectedScheduleSecond = shuttleList.find(shuttle => shuttle.shuttleName == "AM Whistler Elaho Drop Off")
                                                                                                updatedRows[1].shuttleTypeId = selectedScheduleSecond.shuttleId;
                                                                                                updatedRows[1].shuttleType = selectedScheduleSecond.shuttleName;
                                                                                                setShuttleRows(updatedRows)
                                                                                            } else if (values.TripName == "AM Cheak") {
                                                                                                var selectedScheduleSecond = shuttleList.find(shuttle => shuttle.shuttleName == "AM Whistler Cheak Drop Off")
                                                                                            }
                                                                                        } else if (shuttleRows[0].shuttleType == "PM Whistler Elaho Pickup" || shuttleRows[0].shuttleType == "PM Whistler Cheak Pickup") {
                                                                                            var selectedScheduleSecond = shuttleList.find(shuttle => shuttle.shuttleName == "PM Combined Whistler Drop Off")
                                                                                        } else if (shuttleRows[0].shuttleType == "PM Combined Whistler Drop Off" && values.TripName == "PM Cheak") {
                                                                                            var selectedScheduleSecond = shuttleList.find(shuttle => shuttle.shuttleName == "PM Whistler Cheak Pickup")
                                                                                        } else if (shuttleRows[0].shuttleType == "PM Combined Whistler Drop Off" && values.TripName == "PM Elaho") {
                                                                                            var selectedScheduleSecond = shuttleList.find(shuttle => shuttle.shuttleName == "PM Whistler Elaho Pickup")
                                                                                        }
                                                                                        shuttleRows[1].shuttleTypeId = selectedScheduleSecond.shuttleId;
                                                                                        shuttleRows[1].shuttleType = selectedScheduleSecond.shuttleName;
                                                                                        setShuttleRows(shuttleRows)

                                                                                        const { startTime, totalMinutes } = selectedScheduleSecond || {};
                                                                                        if (startTime && totalMinutes) {
                                                                                            const { formattedStartTime, formattedEndTime } = calculateEndTime(startTime, totalMinutes, values.TripDate);
                                                                                            updatedRows[1].startTime = formattedStartTime;
                                                                                            updatedRows[1].endTime = formattedEndTime;
                                                                                            setValues({
                                                                                                ...values,
                                                                                                StartTime: formattedStartTime,
                                                                                                EndTime: formattedEndTime
                                                                                            });
                                                                                            setShuttleStartTime(updatedRows);
                                                                                            setShuttleEndTime(updatedRows);
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        {shuttleList.map(option => (
                                                                            <MenuItem key={option.shuttleId} value={option.shuttleId}>
                                                                                {option.shuttleName}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '38%' }}>
                                                                    <TextField
                                                                        style={{ width: "90%", minWidth: '160px', paddingRight: '15px' }}
                                                                        className="filterDropdown"
                                                                        id="roleName"
                                                                        select
                                                                        label="Role"
                                                                        helperText=""
                                                                        variant="standard"
                                                                        name={`shuttleRows[${index}].roleId`}
                                                                        // value={filteredRows[index]?.roleName}
                                                                        value={row.roleId}
                                                                        onChange={e => {
                                                                            const updatedRows = [...shuttleRows];
                                                                            updatedRows[index].roleId = e.target.value;
                                                                            setShuttleRows(updatedRows);
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: `shuttleRows[${index}].roleName`
                                                                                }
                                                                            });
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        {roleList
                                                                            .filter(option => option.roleName.toLowerCase().includes("driver"))
                                                                            .map(option => (
                                                                                <MenuItem key={option.roleId} value={option.roleId}>
                                                                                    {option.roleName}
                                                                                </MenuItem>
                                                                            ))
                                                                        }

                                                                    </TextField>
                                                                    {errors.shuttleRows?.[index]?.roleId && (
                                                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.shuttleRows[index].roleId}</div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '38%' }}>
                                                                    {/* <TextField
                                                                        style={{ width: "80%" }}
                                                                        className="filterDropdown"
                                                                        id="user"
                                                                        select
                                                                        label="User"
                                                                        helperText=""
                                                                        variant="standard"
                                                                        name={`shuttleRows[${index}].userId`}
                                                                        value={row.userId}
                                                                        onChange={(e, newValue) => {
                                                                            const updatedRows = [...shuttleRows];
                                                                            updatedRows[index].userId = e.target.value;
                                                                            setShuttleRows(updatedRows);
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: `shuttleRows[${index}].fullName`
                                                                                }
                                                                            });
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        {userList.map(option => (
                                                                            <MenuItem key={option.userId} value={option.userId}>
                                                                                {option.fullName}
                                                                            </MenuItem>
                                                                        ))
                                                                        }

                                                                    </TextField> */}
                                                                    <Autocomplete
                                                                        style={{ width: '90%', minWidth: '160px', paddingRight: '15px' }}
                                                                        className="filterDropdown"
                                                                        id="roleName"
                                                                        options={row.roleId ? userList.filter(user => user.userRoles.map(role => role.roleId).includes(row.roleId)) : userList}
                                                                        getOptionLabel={(option) => option.fullName}
                                                                        value={userList.find((user) => user.userId === row.userId) || null} // Adjust value prop
                                                                        onChange={(e, newValue) => {
                                                                            const updatedRows = [...shuttleRows];
                                                                            updatedRows[index].userId = newValue ? newValue.userId : null; // Handle undefined values
                                                                            setShuttleRows(updatedRows);
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: `shuttleRows[${index}].fullName`,
                                                                                },
                                                                            });
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                        renderInput={(params) => (
                                                                            <TextField {...params} fullWidth label="User" variant="standard" helperText="" />
                                                                        )}
                                                                    />
                                                                    {errors.shuttleRows?.[index]?.userId && (
                                                                        <div className="errMsg" style={{ marginTop: '5px' }}>
                                                                            {errors.shuttleRows[index].userId}
                                                                        </div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '10%' }}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DateTimePicker']}>
                                                                            <DateTimePicker label="Start Date and Time"
                                                                                onChange={(e) => {
                                                                                    const updatedRows = [...shuttleRows];
                                                                                    updatedRows[index].startTime = e;
                                                                                    setShuttleRows(updatedRows);
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: `shuttleStartTime-${index}`
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                // value={dayjs(shuttleStartTime)}
                                                                                value={dayjs(shuttleRows[index].startTime)}
                                                                                onBlur={handleBlur}
                                                                                slotProps={{ textField: { style: { width: '100%', paddingRight: '15px', boxSizing: 'border-box' } } }}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '10%' }}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DateTimePicker']}>
                                                                            <DateTimePicker label="End Date and Time"
                                                                                onChange={(e) => {
                                                                                    const updatedRows = [...shuttleRows];
                                                                                    updatedRows[index].endTime = e;
                                                                                    setShuttleRows(updatedRows);
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: `shuttleEndTime-${index}`
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                // value={dayjs(shuttleEndTime)}
                                                                                value={dayjs(shuttleRows[index].endTime)}
                                                                                onBlur={handleBlur}
                                                                                slotProps={{ textField: { style: { width: '100%', paddingRight: '15px', boxSizing: 'border-box' } } }}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                </TableCell>
                                                                <TableCell align="center" style={{ width: '10%' }}>
                                                                    <FormControlLabel control={
                                                                        <Checkbox
                                                                            color="warning"
                                                                            checked={row.isStandby}
                                                                            onChange={(e) => handleShuttleChecked(e, index)}
                                                                        />}
                                                                        // label="StandBy"
                                                                        style={{ marginTop: '15px', fontSize: '10px' }} />
                                                                    <div className="actionListDeleteIconPosition">
                                                                        <Tooltip title="delete" aria-label="delete">
                                                                            <IconButton className="actionBtn" aria-label="delete" onClick={() => removeShuttleRows(index)}>
                                                                                <DeleteIcon className="actionIcon" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <TableContainer>
                                                <Grid className="d-flex justify-content-between align-items-center" style={{ marginTop: '15px' }}>
                                                    <div className='d-flex justify-content-center flex-grow-1'>
                                                        <PageTitle>List of Resources</PageTitle>
                                                    </div>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                                            style={{
                                                                borderRadius: "50%",
                                                                backgroundColor: "rgb(245, 147, 0)",
                                                                color: "white",
                                                                padding: "5px",
                                                                fontSize: "20px",
                                                                cursor: "pointer",
                                                                marginTop: '90px'
                                                            }}
                                                            onClick={addNewResourcesListRow}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Table
                                                    className={classes.table}
                                                    aria-labelledby="tableTitle"
                                                    aria-label="enhanced table"
                                                >
                                                    <EnhancedTableHeadResources
                                                        classes={classes}
                                                        order={order}
                                                        orderBy={orderBy}
                                                        onRequestSort={handleRequestSort} />
                                                    <TableBody>

                                                        {resourcesFilteredRows.map((row, index) => (
                                                            < TableRow
                                                                hover
                                                                tabIndex={-1}
                                                                key={resourcesFilteredRows.id}
                                                            >
                                                                <TableCell align="left" style={{ width: '27%' }}>
                                                                    <TextField
                                                                        style={{ width: "80%" }}
                                                                        className="filterDropdown"
                                                                        id="resource"
                                                                        select
                                                                        label="Resources"
                                                                        helperText=""
                                                                        variant="standard"
                                                                        // value={filteredRows[index]?.resourceId}
                                                                        value={row.resourceId}
                                                                        onChange={e => {
                                                                            const updatedRows = [...resourcesFilteredRows];
                                                                            updatedRows[index].resourceId = e.target.value;
                                                                            // updatedRows[index].resourceId = resourcesList.find(r => r.resourceName === e.target.value)?.resourceId;
                                                                            setResourcesFilteredRows(updatedRows);
                                                                            handleBlur({
                                                                                target: {
                                                                                    name: `resourcesFilteredRows[${index}].resourceName`
                                                                                }
                                                                            });
                                                                        }}
                                                                        onBlur={handleBlur}
                                                                    >
                                                                        {resourcesList.map(option => (
                                                                            <MenuItem key={option.resourceId} value={option.resourceId}>
                                                                                {option.resourceName}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </TextField>
                                                                    {errors.filteredRows?.[index]?.roleName && (
                                                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.filteredRows[index].roleName}</div>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '30%' }}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DateTimePicker']}>
                                                                            <DateTimePicker label="Start Date and Time"
                                                                                onChange={(e) => {
                                                                                    const updatedRows = [...resourcesFilteredRows];
                                                                                    updatedRows[index].startTime = e;
                                                                                    setResourcesFilteredRows(updatedRows);
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: `resourcesStartTime-${index}`
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                value={dayjs(row.startTime)}
                                                                                onBlur={handleBlur}
                                                                                slotProps={{ textField: { style: { width: '80%' } } }}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                    {/* {errors.filteredRows?.[index]?.StartTime && (
                                                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.filteredRows[index].StartTime}</div>
                                                                    )} */}
                                                                </TableCell>
                                                                <TableCell align="left" style={{ width: '30%' }}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DemoContainer components={['DateTimePicker']}>
                                                                            <DateTimePicker label="End Date and Time"
                                                                                onChange={(e) => {
                                                                                    const updatedRows = [...resourcesFilteredRows];
                                                                                    updatedRows[index].endTime = e;
                                                                                    setResourcesFilteredRows(updatedRows);
                                                                                    handleBlur({
                                                                                        target: {
                                                                                            name: `resourcesEndTime-${index}`
                                                                                        }
                                                                                    });
                                                                                }}
                                                                                value={dayjs(row.endTime)}
                                                                                onBlur={handleBlur}
                                                                                slotProps={{ textField: { style: { width: '80%' } } }}
                                                                            />
                                                                        </DemoContainer>
                                                                    </LocalizationProvider>
                                                                    {/* {errors.filteredRows?.[index]?.EndTime && (
                                                                        <div className="errMsg" style={{ marginTop: '5px' }}>{errors.filteredRows[index].EndTime}</div>
                                                                    )} */}
                                                                </TableCell>
                                                                <TableCell align="center">
                                                                    <FormControlLabel control={
                                                                        <Checkbox
                                                                            color="warning"
                                                                            checked={row.isStandby}
                                                                            onChange={(e) => handleIsStandbyChecked(e, index)}
                                                                        />}
                                                                        // label="StandBy"
                                                                        style={{ marginTop: '15px', fontSize: '10px' }} />
                                                                    <div className="actionListDeleteIconPosition">
                                                                        <Tooltip title="delete" aria-label="delete">
                                                                            <IconButton className="actionBtn" aria-label="delete" onClick={() => removeResourcesListRow(index)}>
                                                                                <DeleteIcon className="actionIcon" />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>



                                            <Grid container spacing={4} style={{ marginTop: "-20px" }}>
                                                <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right', paddingRight: '15px' }}>
                                                    <Button variant="outlined" color="primary" className="noShadow cancelBtn " onClick={() => navigate('/schedule')}>
                                                        Cancel
                                                    </Button>&nbsp;&nbsp;
                                                    <Button variant="contained" color="primary" type="submit" className="noShadow addBtn" disabled={isSubmitting}>
                                                        Save
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                </div>
                            </TableWrapper>
                        </form>
                    )
                }}
            </Formik>
        </div >
    )
}

