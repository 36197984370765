import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { Button, Grid } from '@mui/material';
import { FilterInput } from '../../../shared/components/FilterInput';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../../shared/components/Loader';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';


const TableWrapper = styled.div`
.css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #F59300 !important;
}
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                padding : 10px;
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListInfoIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListInfoIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 5fr 5fr 3fr 3fr 1fr;
        background-color: rgba(245, 147, 0, 0.1);
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding: 15px 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
    .filterAutocomplete{
        .dialogStyle {
        height : 200px ;
        width : 1000px ;
    }
    .closeIcon { 
        position: absolute;
        left: 450px;
        right: 0px;
        top: 20px;
        bottom: 0px;
    }
    .MuiFilledInput-root {
        background-color: #ffffff;
        border-radius: 0;
        height : 30px;
    }
    .MuiFilledInput-input {
        padding: 5px 0px 5px 5px;
        font-size: 12px;
    }
    .MuiFilledInput-underline:before {
        border: 0;
    }
    .MuiInputLabel-filled {
        transform: translate(12px,7px) scale(1);
        font-size: 12px;
    }
    .MuiFilledInput-underline:after {
        border: 0;
    }
    .MuiSelect-select:focus {
        background-color: #ffffff;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px,-12px) scale(0.90) !important;
        color: #F59300;
    }
    .css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
        height : 0.2px !important;
    }
   
}
.css-1qqsdnr-MuiAutocomplete-root .MuiFilledInput-root {
    padding-top : 0px !important;
}

@media only screen and (max-width: 600px) {
    .filterRow , .filterRowAdmin{
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)) !important;
        row-gap : 10px;
    }
    .materialTableStyle td {
        font-size: 16px !important;
    }
    
  }
  @media screen and (max-width: 40em)
  .responsiveTable tbody tr {
      border-spacing: 10px !important;
      padding: 0.25em !important;
  }
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}



const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function UserEarnings() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [message, setMessage] = React.useState(" ");
    const [loading, setLoading] = React.useState(false);
    var [filteredRows, setFilteredRows] = React.useState([]);
    var [startDate, setStartDate] = React.useState("");
    var [endDate, setEndDate] = React.useState("");
    var [startDateRange, setStartDateRange] = React.useState();
    var [endDateRange, setEndDateRange] = React.useState();
    const navigate = useNavigate();
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    var [openReminder, setOpenReminder] = React.useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    var headCells = [
        { id: 'tripUser', numeric: false, label: 'Staff Name' },
        { id: 'tripRate', numeric: false, label: 'Earnings' },
        { id: 'tripHours', numeric: false, label: 'Hours' },
    ];

    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        if (tokens.userType != "Admin") {
            headCells = headCells.filter(h => h.id != 'tripRate')
        }

        return (

            <Thead>
                <Tr>
                    {headCells.map((headCell) => (
                        <Th
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </Th>
                    ))}
                </Tr>
            </Thead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    const handleClose = () => {
        setOpenReminder(false)
        localStorage.setItem("login", false);
    };
    var [userProfile, setUserProfile] = React.useState();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        userProfile = localStorage.getItem("loginRes");
        userProfile = JSON.parse(userProfile)
        setUserProfile(userProfile)
        const isLogin = queryParams.get("islogin")
        if (isLogin && userProfile?.missingInfo?.length > 0) {
            openReminder = true
            setOpenReminder(openReminder)
        }
        userEarningsList()
    }, [])



    const getUserEarningsList = () => {
        setLoading(true);
        setTimeout(() => {
            const RAFTCO_USER_PAYROLL = config.API_URL + "/Trip/api/Trip/userpayroll"
            const dateRange = {
                fromDate: startDate,
                toDate: endDate,
                userType: tokens.userType,
                tripUserId: tokens.id,
            }
            axios.post(RAFTCO_USER_PAYROLL, dateRange, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        filteredRows = res.data.data;
                        setFilteredRows(filteredRows)
                    }
                    else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                })
                .finally(() => {
                    setLoading(false);
                })
        })
    }

    const getBatchDate = async () => {
        const RAFTCO_DATERANGE_URL = config.API_URL + "/Dashboard/api/daterange"
        let res = await axios.get(RAFTCO_DATERANGE_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
        if (res.data.successCode === 1) {
            startDateRange = res.data.data[0]
            setStartDateRange(startDateRange)
            endDateRange = res.data.data[1]
            setEndDateRange(endDateRange)
            var firstDateArray = res.data.data[0].split("T")
            startDate = firstDateArray[0]
            setStartDate(startDate)
            var lastDateArray = res.data.data[1].split("T")
            endDate = lastDateArray[0]
            setEndDate(endDate)
        } else {
            setMessage(
                <div className="message">
                    <div className="alert alert-danger" role="alert">
                        "An error occured,try again later"
                    </div>
                </div>)
            setTimeout(() => { setMessage("") }, 5000)
        }

    }


    const getAwaitedEarningsList = async () => {
        const result = await getBatchDate()
        getUserEarningsList()
    }


    const userEarningsList = () => {
        var batchDate = sessionStorage.getItem("user_daterange")
        if (batchDate) {

            batchDate = JSON.parse(batchDate)
            startDate = batchDate.startDate
            setStartDate(startDate)
            endDate = batchDate.endDate
            setEndDate(endDate)
            getUserEarningsList()
        } else {
            getAwaitedEarningsList()
        }
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const searchTrip = () => {

        var userDateRange = {
            "startDate": startDate,
            "endDate": endDate,
            "userType": tokens.userType,
            tripUserId: tokens.id,
        }
        userDateRange = JSON.stringify(userDateRange)
        sessionStorage.setItem("user_daterange", userDateRange);

        const RAFTCO_USER_PAYROLL = config.API_URL + "/Trip/api/Trip/userpayroll"
        const dateRange = {
            fromDate: startDate,
            toDate: endDate,
            userType: tokens.userType,
            tripUserId: tokens.id,
        }
        axios.post(RAFTCO_USER_PAYROLL, dateRange, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    filteredRows = res.data.data;
                    setFilteredRows(filteredRows)
                }
                else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }

    const userSummary = (id) => {
        navigate("/user/summary/" + id + "?startDate=" + startDate + "&endDate=" + endDate)
    }

    const downloadcsv = () => {
        let postData;
        if (tokens.userType == "Admin") {
            postData = {
                fromDate: startDate,
                toDate: endDate,
                tripUserId: 0,
                userType: "Admin"
            }
        } else {
            postData = {
                fromDate: startDate,
                toDate: endDate,
                tripUserId: tokens.id,
                userType: tokens.userType
            }
        }
        const RAFTCO_URL = config.API_URL + "/Trip/api/Trip/userpayrollexport"
        axios.post(RAFTCO_URL, postData, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    const data = res.data.data;
                    const element = document.createElement("a");
                    const file = new Blob([data], { type: 'text/plain' });
                    element.href = URL.createObjectURL(file);
                    element.download = 'payrollsummary.csv';
                    document.body.appendChild(element);
                    element.click();
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    return (

        <TableWrapper>
            {message}
            <div className={`${classes.root} materialTableStyle`}>
                <div>
                    <Modal
                        open={openReminder}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style} className="text-center">
                            <div className='text-right'>
                                <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={handleClose} />
                            </div>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                Please complete the following fields in your profile:
                            </Typography>
                            <div className='text-left mx-3'>
                                <ul style={{ listStylePosition: 'inside', margin: 0, padding: 0 }}>
                                    {userProfile?.missingInfo.map((item, index) => (
                                        <li key={index}>{item}</li>
                                    ))}
                                </ul>
                            </div>
                            <Grid container spacing={4} className="mt-1">
                                <Grid item xs={12} sm={12} md={12} className="text-center">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => handleClose()}>
                                        Later
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => navigate("/user/edit/" + userProfile.id)}>
                                        Complete Now
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Modal>
                </div>
                <div className="filterRow" >
                    <div>
                        <FilterInput placeholder="Start Date(yyyy-mm-dd)" type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                    </div>
                    <div>
                        <FilterInput placeholder="End Date(yyyy-mm-dd)" type="date" value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                    </div>
                    <div>
                        <Button type="submit" variant="contained" color="primary" className="noShadow w-100 addBtn" onClick={() => searchTrip()} >
                            Search
                        </Button>
                    </div>
                    <div>
                        <Tooltip title="Download CSV" aria-label="Download">
                            <IconButton className="actionCSVBtn csvDownloadIcon" aria-label="Download" onClick={() => { downloadcsv() }}>
                                <GetAppIcon className="actionIcon" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
                <Table
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />
                    <Tbody>

                        {stableSort(filteredRows, getComparator(order, orderBy))
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((filteredRows, index) => {
                                return (
                                    <Tr
                                        hover
                                        tabIndex={-1}
                                        key={filteredRows.tripUserId}
                                    >
                                        <Td align="left" style={{ cursor: "pointer" }} onClick={() => userSummary(filteredRows.tripUserId)}>{filteredRows.tripUser}</Td>
                                        {tokens.userType == "Admin" && <Td>
                                            {'\u0024' + filteredRows.tripRate}
                                        </Td>}
                                        <Td>
                                            {filteredRows.tripHours}
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </Tbody>
                </Table>
            </div>
        </TableWrapper>
    );
}


