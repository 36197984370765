import React, { useEffect } from 'react';
import config from '../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import Moment from 'moment';
import PageTitle from '../../shared/components/PageTitle';
import { Loader } from '../../shared/components/Loader';
import { useNavigate } from 'react-router-dom';


const TableWrapper = styled.div`

    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
            background-color: rgba(245, 147, 0, 0.1);
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListInfoIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListInfoIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #F59300;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #F59300;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
            color : white !important;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr 2fr 2fr 1fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
    .filterAutocomplete{
        .dialogStyle {
        height : 200px ;
        width : 1000px ;
    }
    .closeIcon { 
        position: absolute;
        left: 450px;
        right: 0px;
        top: 20px;
        bottom: 0px;
    }
    .MuiFilledInput-root {
        background-color: #ffffff;
        border-radius: 0;
        height : 30px;
    }
    .MuiFilledInput-input {
        padding: 5px 0px 5px 5px;
        font-size: 12px;
    }
    .MuiFilledInput-underline:before {
        border: 0;
    }
    .MuiInputLabel-filled {
        transform: translate(12px,7px) scale(1);
        font-size: 12px;
    }
    .MuiFilledInput-underline:after {
        border: 0;
    }
    .MuiSelect-select:focus {
        background-color: #ffffff;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px,-12px) scale(0.90) !important;
        color: #F59300;
    }}
    
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'tripDate', numeric: false, label: 'Trip Date' },
    { id: 'tripName', numeric: false, label: 'Trip Name' },
    { id: 'leaderFullName', numeric: false, label: 'Submitted By' },
    { id: 'tripCost', numeric: false, label: 'Earnings' },
    { id: 'tripItemCount', numeric: false, label: 'Number of Staff' },
];

const headCellsNoTimeentryUsers = [
    { id: 'fullName', numeric: false, label: 'Staff Name' },
    { id: 'email', numeric: false, label: 'Staff Email' }
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

function EnhancedTableHeadUsers(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCellsNoTimeentryUsers.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};

EnhancedTableHeadUsers.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState();
    const [userpage, setUserPage] = React.useState(0);
    const [rowsPerUserPage, setRowsPerUserPage] = React.useState();
    const [payrollpage, setPayrollPage] = React.useState(0);
    const [rowsPerPayrollPage, setRowsPerPayrollPage] = React.useState();
    const [message, setMessage] = React.useState(" ");
    const [loading, setLoading] = React.useState(false);
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    var [startDate, setStartDate] = React.useState();
    var [endDate, setEndDate] = React.useState();
    var [startDateRange, setStartDateRange] = React.useState();
    var [endDateRange, setEndDateRange] = React.useState();
    var [userList, setUserList] = React.useState([]);
    const [tripLeaderId, setTripLeaderId] = React.useState(0);
    const [tripUserId, setTripUserId] = React.useState(0);
    const userTypeArray = ["Admin", "Leader"];
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    const navigate = useNavigate();


    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            const RAFTCO_DATERANGE_URL = config.API_URL + "/Dashboard/api/daterange"
            axios.get(RAFTCO_DATERANGE_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        startDateRange = res.data.data[0]
                        setStartDateRange(startDateRange)
                        endDateRange = res.data.data[1]
                        setEndDateRange(endDateRange)
                        var firstDateArray = res.data.data[0].split("T")
                        startDate = firstDateArray[0]
                        setStartDate(startDate)
                        var lastDateArray = res.data.data[1].split("T")
                        endDate = lastDateArray[0]
                        setEndDate(endDate)

                        const RAFTCO_TRIP_URL = config.API_URL + "/Trip/api/Trip/searchalltrips"
                        const data = {
                            fromDate: startDate,
                            toDate: endDate,
                            tripLeaderId: tripLeaderId,
                            tripUserId: tripUserId
                        }
                        axios.post(RAFTCO_TRIP_URL, data, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {
                                if (res.data.successCode === 1) {
                                    rows = res.data.data
                                    setRows(rows);
                                    filteredRows = rows;
                                    setFilteredRows(filteredRows)
                                } else {
                                    setMessage(
                                        <div className="message">
                                            <div className="alert alert-danger" role="alert">
                                                "An error occured,try again later"
                                            </div>
                                        </div>)
                                    setTimeout(() => { setMessage("") }, 5000)
                                }
                            }, 400).catch(err => {
                                if (err.response.status == 401) {
                                    navigate("/login")
                                }
                            })

                        const RAFTCO_USER_NOENTRY = config.API_URL + "/Trip/api/Trip/notimeentry"
                        const postData = {
                            fromDate: startDate,
                            toDate: endDate
                        }
                        axios.post(RAFTCO_USER_NOENTRY, postData, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {
                                if (res.data.successCode === 1) {
                                    userList = res.data.data
                                    setUserList(userList)
                                } else {
                                    setMessage(
                                        <div className="message">
                                            <div className="alert alert-danger" role="alert">
                                                "An error occured,try again later"
                                            </div>
                                        </div>)
                                    setTimeout(() => { setMessage("") }, 5000)
                                }
                            }, 400).catch(err => {
                                if (err.response.status == 401) {
                                    navigate("/login")
                                }
                            })
                            .finally(() => {
                                setLoading(false)
                            }
                            );
                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                })
        });
    }, [])

    const UpdateTrip = (tripId) => {
        navigate("/trip/edit/" + tripId)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const deleteTrip = (filteredRows) => {

        const RAFTCO_DELETE_URL = config.API_URL + "/Trip/api/Trip/delete/" + filteredRows.tripId
        axios.delete(RAFTCO_DELETE_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-success" role="alert">
                                "Trip deleted successfully"
                            </div>
                        </div>)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                }
                setTimeout(() => { setMessage("") }, 5000)
                navigate("/trip/list")
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }

    const handleEditDelete = () => {
        if (userTypeArray.includes(tokens.userType))
            return (
                <div>
                    <div className="actionListEditIconPosition">
                        <Tooltip title="Edit" aria-label="edit">
                            <IconButton className="actionBtn" aria-label="edit" onClick={() => { UpdateTrip(filteredRows.tripId) }}>
                                <EditIcon className="actionIcon" />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className="actionListDeleteIconPosition">
                        <Tooltip title="delete" aria-label="delete">
                            <IconButton className="actionBtn" aria-label="delete" onClick={() => { deleteTrip(filteredRows) }}>
                                <DeleteIcon className="actionIcon" />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            )
    }

    const userSummary = (id) => {
        navigate("/user/summary/" + id)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeUserPage = (event, newPage) => {
        setUserPage(newPage);
    };

    const handleChangeRowsPerUserPage = (event) => {
        setRowsPerUserPage(parseInt(event.target.value, 10));
        setUserPage(0);
    };

    const handleChangePayrollPage = (event, newPage) => {
        setPayrollPage(newPage);
    };

    const handleChangeRowsPerPayrollPage = (event) => {
        setRowsPerPayrollPage(parseInt(event.target.value, 10));
        setPayrollPage(0);
    };

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    return (
        <div>
            <TableWrapper>
                {message}
                <div className={`${classes.root} materialTableStyle`}>
                    <TableContainer>
                        <div className='d-flex justify-content-center'>
                            <PageTitle>Trips in Current Period</PageTitle>
                        </div>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>

                                {stableSort(filteredRows, getComparator(order, orderBy))
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((filteredRows, index) => {
                                        if (filteredRows.tripDate) {
                                            filteredRows.tripDate = Moment(Date.parse(filteredRows.tripDate)).format('MM/DD/YYYY')
                                        }
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={filteredRows.tripId}
                                            >
                                                <TableCell align="left">{filteredRows.tripDate}</TableCell>
                                                <TableCell>
                                                    {filteredRows.tripName}
                                                </TableCell>

                                                <TableCell align="left">{filteredRows.leaderFullName}</TableCell>
                                                <TableCell align="left">{'\u0024' + filteredRows.tripCost}</TableCell>
                                                <TableCell align="left">{filteredRows.tripItemCount}</TableCell>

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            className="paginationLabel"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={filteredRows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                    </TableContainer>
                    <TableContainer>
                        <div className='d-flex justify-content-center mt-2'>
                            <PageTitle>Staff Without Entries In Current Period</PageTitle>
                        </div>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            aria-label="enhanced table"
                        >
                            <EnhancedTableHeadUsers
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>

                                {stableSort(userList, getComparator(order, orderBy))
                                    //  .slice(payrollpage * rowsPerPayrollPage, payrollpage * rowsPerPayrollPage + rowsPerPayrollPage)
                                    .map((userList, index) => {

                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={userList.userId}
                                            >
                                                <TableCell align="left">{userList.fullName}</TableCell>
                                                <TableCell>
                                                    {userList.email}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {/* <TablePagination
                            className="paginationLabel"
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={userList.length}
                            rowsPerPage={rowsPerPayrollPage}
                            page={payrollpage}
                            onPageChange={handleChangePayrollPage}
                            onRowsPerPageChange={handleChangeRowsPerPayrollPage}
                        /> */}
                    </TableContainer>
                </div>

            </TableWrapper >
        </div>
    );
}


