import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import styled from 'styled-components';
import { Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, Grid } from '@mui/material';
import PageTitle from '../../../shared/components/PageTitle';
import { Formik } from 'formik';
import Moment from 'moment';


const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
         background-color: #F59300 !important;
    }
   
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListEmailIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
       
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 2fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
       
    }
`;


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function TripDate() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    const navigate = useNavigate();
    const [addBatchPopup, setAddBatchPopup] = React.useState(true);
    const [batchMessage, setBatchMessage] = React.useState(" ");
    var [tripLockDate, setTripLockDate] = React.useState("");
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)

    useEffect(() => {

        const MOBILESURETY_SYNC_URL = config.API_URL + "/Trip/api/Trip/lockget"
        axios.get(MOBILESURETY_SYNC_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                   tripLockDate = res.data.data.tripLockDate
                   setTripLockDate(tripLockDate)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }, [])

    return (

        <TableWrapper>
            {batchMessage}
            <div className={`${classes.root} materialTableStyle`}>
                <div>
                    <div>
                    </div>
                    <div></div>
                    <div></div>
                    <div className="m-2">
                        <Dialog open={addBatchPopup} closeonescape="true" maxWidth="true" >
                            <TableWrapper>
                                <DialogContent className="dialogStyle">
                                    <Formik
                                        enableReinitialize={true}
                                        initialValues={{
                                            TripLockDate: tripLockDate
                                        }}
                                        validate={values => {

                                            const errors = {};
                                            if (!values.TripLockDate) {
                                                errors.TripLockDate = 'Required!';
                                            }
                                            return errors;
                                        }}

                                        const handleMouseDownPwd={(event) => {
                                            event.preventDefault();
                                        }}
                                        onSubmit={(values, { setSubmitting }) => {
                                            const MOBILESURETY_URL = config.API_URL + "/Trip/api/Trip/updatelock/" + values.TripLockDate
                                            const token = config.TOKEN
                                            axios.get(MOBILESURETY_URL, {
                                                headers: {
                                                    'Authorization': `Basic ${tokens.jwtToken}`
                                                }
                                            })
                                                .then((res) => {
                                                    {
                                                        if (res.data.successCode === 1) {
                                                            setMessage(
                                                                <div className="message">
                                                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                                        Payroll Lock added successfully!
                                                                    </div>
                                                                </div>)
                                                        } else {
                                                            setMessage(
                                                                <div className="message">
                                                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                                        "An error occured, contact support".
                                                                    </div>
                                                                </div>)
                                                        }
                                                        setTimeout(() => { setAddBatchPopup(false) }, 3000)
                                                        setTimeout(() => { setMessage("") }, 3000)
                                                        setTimeout(() => {navigate("/trip/list")}, 3000)
                                                        
                                                    }
                                                })
                                            setSubmitting(false)
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting,

                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                {message}
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <PageTitle>Add Payroll Lock</PageTitle>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6} md={6} className="text-right">

                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={4}>
                                                    <Grid item xs={12} sm={6} md={6}>
                                                        <TextField label="Trip Lock Date" type="date" variant="standard" value={values.TripLockDate || ''} onBlur={handleBlur} InputLabelProps={{
                                                            shrink: true,
                                                        }} name="TripLockDate" onChange={handleChange} />
                                                        <div className="errMsg">{errors.TripLockDate && touched.TripLockDate && errors.TripLockDate}</div>
                                                    </Grid>
                                                </Grid>

                                                <Grid container spacing={4} >
                                                    <Grid item xs={12} sm={12} md={12} className="text-right mt-4">
                                                        <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => {setAddBatchPopup(false);navigate("/trip/list")}}>
                                                            Cancel
                                                        </Button>&nbsp;&nbsp;
                                                        <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                                            Submit
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        )
                                        }
                                    </Formik >
                                </DialogContent>
                            </TableWrapper>
                        </Dialog>
                    </div>
                </div>
            </div>
        </TableWrapper>
    );
}


