import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styled from 'styled-components';
import { Badge } from '../../../shared/components/Badge';
import { Loader } from '../../../shared/components/Loader';
import { Button, MenuItem, TextField } from '@mui/material';
import { FilterInput } from '../../../shared/components/FilterInput';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material'
import FolderIcon from '@mui/icons-material/Folder';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const TableWrapper = styled.div`
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
         background-color: #F59300 !important;
    }
   
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListFileIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListFileIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRow {
        display: grid;
        grid-template-columns: 3fr 3fr 3fr 3fr;
        background-color: rgba(245, 147, 0, 0.1);
        height: 60px;
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding-left: 10px;
        padding-right: 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
       
    }
    
`;

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const headCells = [
    { id: 'lastName', numeric: false, label: 'Last Name' },
    { id: 'firstName', numeric: false, label: 'First Name' },
    { id: 'userEmail', numeric: false, label: 'Email' },
];

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
};


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function UserList() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [message, setMessage] = React.useState(" ");
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    var [usersStatus, setUsersStatus] = React.useState("");
    var [search, setSearch] = React.useState("");
    var [checked, setChecked] = React.useState();
    var [disable, setDisable] = React.useState(true);
    const [open, setOpen] = React.useState(false);
    const [copied, setCopied] = React.useState(false);
    const [userUrl, setUserUrl] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    var [selectAdmin, setSelectAdmin] = React.useState("");
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    const navigate = useNavigate();
    var [userEmail, setUserEmail] = React.useState("");
    var [deletePopup, setDeletePopup] = React.useState(false);
    // const [deleteMessage, setDeleteMessage] = React.useState(" ");
    const deleteMessage = () => toast("Staff deleted successfully");
    const errorMessage = () => toast("An error occured,try again later");


    useEffect(() => {
        userList()

    }, [])

    const userList = () => {
        setLoading(true);
        setTimeout(() => {
            const RAFTCO_USER_URL = config.API_URL + "/api/Users"
            axios.get(RAFTCO_USER_URL,
                {
                    headers: {
                        'Authorization': `Basic ${tokens.jwtToken}`
                    }
                }
            )
                .then((res) => {
                    if (res.data.successCode === 1) {
                        rows = res.data.data
                        setRows(rows);
                        filteredRows = rows;
                        setFilteredRows(filteredRows)
                        setDisable(false)
                    } else {
                        // setMessage(
                        //     <div className="message">
                        //         <div className="alert alert-danger" role="alert">
                        //             "An error occured,try again later"
                        //         </div>
                        //     </div>)
                        // setTimeout(() => { setMessage("") }, 5000)
                        errorMessage()
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                }).finally(() => {
                    setLoading(false);
                });
        });
    }

    const updateUser = (userId) => {
        navigate("/user/edit/" + userId)
    }

    const handleUserStatus = (event) => {
        usersStatus = event.target.value;
        setUsersStatus(usersStatus);
        if (usersStatus === 'All') {
            filteredRows = rows;
            setFilteredRows(filteredRows)
        } else {
            filteredRows = rows.filter(a => a.isActive === usersStatus)
            setFilteredRows(filteredRows)
        }
    }
    const handleSelectAdmin = (event) => {
        selectAdmin = event.target.value;
        setSelectAdmin(selectAdmin);
        if (selectAdmin === "admin") {
            filteredRows = rows.filter(a => a.userType.toLowerCase() === 'admin')
            setFilteredRows(filteredRows)
        } if (selectAdmin === "leader") {
            filteredRows = rows.filter(a => a.userType.toLowerCase() === 'leader')
            setFilteredRows(filteredRows)
        } if (selectAdmin === "user") {
            filteredRows = rows.filter(a => a.userType.toLowerCase() === 'user')
            setFilteredRows(filteredRows)
        }
        if (selectAdmin === 'All') {
            filteredRows = rows;
            setFilteredRows(filteredRows)
        }
    }

    const handleSearch = (event) => {
        search = event.target.value;
        setSearch(search);
        filteredRows = rows.filter(a => (a.firstName ? a.firstName.toLowerCase().includes(search.toLowerCase()) : a.firstName) || (a.lastName != null ? a.lastName.toLowerCase().includes(search.toLowerCase()) : a.lastName) || (a.userName != null ? a.userName.toLowerCase().includes(search.toLowerCase()) : a.userName) || (a.userEmail != null ? a.userEmail.toLowerCase().includes(search.toLowerCase()) : a.userEmail))
        setFilteredRows(filteredRows)

    }
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isAdmin = (userType) => {
        if (userType ? userType.toLowerCase() == "admin" : false)
            return (
                <Badge bgColor="#11c5db">Admin</Badge>
            )
        if (userType ? userType.toLowerCase() == "user" : false)
            return (
                <Badge bgColor="#11c5db">User</Badge>
            )
        if (userType ? userType.toLowerCase() == "leader" : false)
            return (
                <Badge bgColor="#11c5db">Leader</Badge>
            )

    }


    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const userStatus = [
        {
            value: 'All',
            label: 'All'
        },
        {
            value: true,
            label: 'Active'
        },
        {
            value: false,
            label: 'Inactive'
        }

    ];
    const AdminUser = [

        {
            value: 'All',
            label: 'All'
        },
        {
            value: "admin",
            label: 'Admin'
        },
        {
            value: "leader",
            label: 'Leader'
        },
        {
            value: "user",
            label: 'User'
        }
    ];

    const deleteUser = () => {
        setLoading(true)
        setTimeout(() => {
            const RAFTCO_DELETE_URL = config.API_URL + "/api/Users/delete/" + userEmail
            axios.delete(RAFTCO_DELETE_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        userList()
                        setTimeout(() => {
                            deleteMessage();
                        }, 2000)
                    } else {
                        setTimeout(() => {
                            errorMessage();
                        }, 2000)
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                })
                .finally(() => {
                    setDeletePopup(false);
                    setLoading(false);
                });
        })
    }

    const openDeletePopup = (email) => {
        userEmail = email;
        setUserEmail(userEmail)
        deletePopup = true;
        setDeletePopup(deletePopup);
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    const handleDelete = (filteredRows) => {
        if (tokens.userType == "Admin")
            return (
                <div className="actionListDeleteIconPosition">
                    <Tooltip title="delete" aria-label="delete">
                        <IconButton className="actionBtn" aria-label="delete" onClick={() => { openDeletePopup(filteredRows.userEmail) }}>
                            <DeleteIcon className="actionIcon" />
                        </IconButton>
                    </Tooltip>
                    <Dialog open={deletePopup} closeonescape="true" maxWidth="true" >
                        <TableWrapper>
                            <DialogContent className="dialogStyle">
                                <div className={`${classes.root} materialTableStyle`}>
                                    Do you want to delete the record?
                                </div>
                                <DialogActions>
                                    <Grid container spacing={4} className="mt-1">
                                        <Grid item xs={12} sm={12} md={12} className="text-center">
                                            <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => setDeletePopup(false)}>
                                                No
                                            </Button>&nbsp;&nbsp;
                                            <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" onClick={() => deleteUser()}>
                                                Yes
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </DialogContent>
                        </TableWrapper>
                        {/* {deleteMessage} */}
                        <ToastContainer theme='dark' />
                    </Dialog>
                </div>
            )
    }

    return (

        <TableWrapper>
            {/* {message} */}
            <ToastContainer theme='dark' />
            <div className={`${classes.root} materialTableStyle`}>
                <div className="filterRow">
                    <div>
                        <FilterInput placeholder="Search" disabled={disable} icon={<FilterListIcon className="filterIconSize" />} value={search} onChange={handleSearch} />
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            className="m-2 filterDropdown"
                            id="status"
                            select
                            label="Select Admin/User"
                            helperText=""
                            variant="filled"
                            value={selectAdmin}
                            onChange={handleSelectAdmin}>
                            {AdminUser.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div>
                        <TextField
                            fullWidth
                            className="m-2 filterDropdown"
                            id="status"
                            select
                            label="Select Status"
                            helperText=""
                            variant="filled"
                            value={usersStatus}
                            onChange={handleUserStatus}>
                            {userStatus.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    </div>
                    <div className="m-2">
                        <Button variant="contained" color="primary" className="noShadow w-100 addBtn " onClick={() => navigate("/user/add")}>
                            New Staff
                        </Button>
                    </div>
                </div>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />
                        <TableBody>

                            {stableSort(filteredRows, getComparator(order, orderBy))
                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((filteredRows, index) => {
                                    return (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={filteredRows.userId}
                                        >

                                            <TableCell align="left">{filteredRows.lastName}</TableCell>
                                            <TableCell>
                                                {filteredRows.firstName}
                                            </TableCell>
                                            <TableCell align="left">
                                                <div>{filteredRows.userEmail}</div>
                                                {isAdmin(filteredRows.userType)}
                                                <div className="actionListEditIconPosition">
                                                    <Tooltip title="Edit" aria-label="edit">
                                                        <IconButton className="actionBtn" aria-label="edit" onClick={() => { updateUser(filteredRows.userId) }}>
                                                            <EditIcon className="actionIcon" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                                {handleDelete(filteredRows)}
                                                <div className="actionListFileIconPosition">
                                                    <Tooltip title="Files" aria-label="files">
                                                        <IconButton className="actionBtn" aria-label="files" onClick={() => { navigate("/user/files/" + filteredRows.userId + "?useremail=" + filteredRows.userEmail) }}>
                                                            <FolderIcon className="actionIcon" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </TableCell>
                                            {/* <TableCell align="left">
                                                                                                                                      
                                            </TableCell> */}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <TablePagination
                     className="paginationLabel"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredRows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
            </div>
        </TableWrapper>
    );
}


