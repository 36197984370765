import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { Button, FormControlLabel, Typography, Card, CardContent, FormControl, Select, Grid, Input, InputLabel, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageTitle from '../../../shared/components/PageTitle';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import Switch from '@mui/material/Switch';
import axios from 'axios';
import config from '../../config.json';
import { Formik } from 'formik';
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faAdd } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../shared/components/Loader';
import { Checkbox } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const UserWrapper = styled.div`
    position: relative;
    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        background-color: #F59300 !important;
    }
    .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
        border : 1px solid #F59300 !important;
        color : #F59300 !important;
    }

    .wrapperWhite {
        background-color: #ffffff;
        padding: 1.5rem;
        border-radius: 18px;
    }

    .MuiTextField-root {
        width: 100%;
    }

    .card-box{
        position: relative;
        overflow: hidden;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        border-radius: 0.5rem;
        color: rgba(0, 0, 0, 0.87);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        background-color: #fff;        
        cursor: pointer;
    }
    .card-indicator {
        position: absolute;
        height: 60%;
        top: 20%;
        width: 0px;
        border-top-right-radius: .75rem;
        border-bottom-right-radius: .75rem;
        left: 0;
        transition: all 0.3s linear;
    }
    .badge-primary {
        color: #fff;
        font-weight: 500;    
    }
    .roleName {
        font-size: 14px;
        font-weight: 500;
    }
    .roleItems { 
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .roleItems:checked ~ .card-indicator {
        width: 6px;
    }
`;

export default function EditUser() {
    const classes = useStyles();
    const [message, setMessage] = React.useState("");
    var [rolesList, setRolesList] = useState([]);
    const [role, setRole] = useState("");
    const [rate, setRate] = useState("");
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    var [roleArray, setRoleArray] = useState([]);
    const [showRoles, setShowRoles] = useState(true);
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    var [rolesUpdated,setRolesUpdated] = useState(false);
    var [userAccess, setUserAccess] = useState(false);
    const navigate = useNavigate();
    var [rows, setRows] = useState(
        {
            "userEmail": "",
            "firstName": "",
            "lastName": "",
            "fullName": "",
            "userType": "",
            "phone": '',
            "isActive": false,
            "userRoles": [],
            "rolesUpdated": false,
        }
    );
    var [allRoles, setAllRoles] = useState([]);
    const userUpdate = () => toast("Staff Updated Successfully!");
    const userUpdateError = () => toast("An error occured, please try again later!");
    var [userProfile, setUserProfile] = useState();
    var [dateOfBirth, setDateOfBirth] = useState();
    var [startDate, setStartDate] = useState();

    useEffect(() => {

        userProfile = localStorage.getItem("loginRes");
        userProfile = JSON.parse(userProfile)
        setUserProfile(userProfile)

        setLoading(true);
        setTimeout(() => {
            const RAFTCO_USER_URL = config.API_URL + "/api/Users/" + id
            axios.get(RAFTCO_USER_URL, {
                headers: {
                    'Authorization': `Basic ${tokens.jwtToken}`
                }
            })
                .then((res) => {
                    if (res.data.successCode === 1) {
                        rows = res.data.data
                        setRows(rows);
                        if (rows?.dateOfBirth) {
                            let dob = rows?.dateOfBirth.split("T");
                            dateOfBirth = dob[0]
                            setDateOfBirth(dateOfBirth)
                        }

                        if (rows?.startDate) {
                            let startDt = rows?.startDate.split("T");
                            startDate = startDt[0]
                            setStartDate(startDate)
                        }
                        roleArray = rows.userRoles
                        setRoleArray(roleArray)
                        userAccess = rows.canAddHours
                        setUserAccess(userAccess);

                        const RAFTCO_ROLES_URL = config.API_URL + "/api/Roles"
                        axios.get(RAFTCO_ROLES_URL, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {
                                if (res.data.successCode === 1) {
                                    allRoles = res.data.data
                                    setAllRoles(allRoles)
                                    rolesList = res.data.data
                                    setRolesList(rolesList);

                                    // if (userAccess) {
                                    //     rolesList = allRoles.filter(r => r.roleName.startsWith("Hour"))
                                    //     setRolesList(rolesList);
                                    // } else {
                                    //     rolesList = res.data.data
                                    //     setRolesList(rolesList);
                                    // }

                                } else {
                                    setMessage(
                                        <div className="message">
                                            <div className="alert alert-danger" role="alert">
                                                "An error occured,try again later"
                                            </div>
                                        </div>)
                                    setTimeout(() => { setMessage("") }, 5000)
                                }
                            }, 400).catch(err => {
                                if (err.response.status == 401) {
                                    navigate("/login")
                                }
                            }).finally(() => {
                                setLoading(false);
                            })

                    } else {
                        setMessage(
                            <div className="message">
                                <div className="alert alert-danger" role="alert">
                                    "An error occured,try again later"
                                </div>
                            </div>)
                        setTimeout(() => { setMessage("") }, 5000)
                    }
                }, 400).catch(err => {
                    if (err.response.status == 401) {
                        navigate("/login")
                    }
                })
        })
    }, [])

    const handleRoles = () => {
        if (rows)
            if (rows.userRoles.length != 0)
                return (
                    rows.userRoles.map((r, index) => { return { roleId: r.roleId, rate: r.rate } })
                )
            else
                return (
                    [{ roleId: 0, rate: 0 }]
                )
    }

    const notify = () => toast("Enter Role and Rate!");

    const addRoles = (values) => {
        if (!role.roleId && !rate) {
            notify()
        } else {
            roleArray.push({ roleId: role.roleId, rate: rate, roleName: role.roleName, isActive: true })
            setRoleArray(roleArray)
            setRole('')
            setRate('')
            setRolesUpdated(true);
            setShowRoles(true)
        }
    }

    const deleteRole = (i) => {
        roleArray[i].isActive = false;
        setRolesUpdated(true);
        setRoleArray([...roleArray])
    }

    const displayRoles = () => {
        // if (showRoles)
        return (
            <Grid container spacing={4} className="pt-4">
                {roleArray.map((r, index) => {
                    if (r.isActive) {
                        return (
                            <Grid item xs={12} sm={4} md={4} key={r.roleId}>
                                <Card style={{ backgroundColor: "#F0F0F0" }}>
                                    <CardContent className="d-flex justify-content-between">
                                        <div>
                                            <Typography>{r.roleName + ' (\u0024' + r.rate + ')'}</Typography>
                                        </div>
                                        <div>
                                            <FontAwesomeIcon icon={faClose} className="closeIcon" onClick={() => deleteRole(index)} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    }
                })}
            </Grid >
        )
    }

    const notifyClickAdd = () => toast("You forgot to click the add button!");
    const notifyDuplicateRole = () => toast("Selected role already added!");

    const handleRole = (e) => {
        if (roleArray.find(t => t.roleId == e.target.value.roleId)) {
            notifyDuplicateRole()
        } else {
            setRole(e.target.value)
        }

    }

    const handleCancel = () => {
        if (userProfile?.userType == "Admin") {
            navigate("/user/list")
        } else {
            navigate("/trip/list")
        }
    }

    const handleUserAccess = (e) => {
        // if (e.target.checked == true) {
        //     let roles = rolesList.filter(r => r.roleName.startsWith("Hour"))
        //     setRolesList(roles);
        // } else {
        //     rolesList = allRoles
        //     setRolesList(rolesList)
        // }
        userAccess = e.target.checked
        setUserAccess(userAccess)
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }

    return (
        <div>
            <ToastContainer theme="dark" />
            <Formik
                enableReinitialize={true}
                initialValues={{
                    FirstName: rows?.firstName, LastName: rows?.lastName, UserEmail: rows?.userEmail, Phone: rows?.phone, UserType: rows?.userType, IsActive: rows?.isActive,
                    PreferredName: rows?.preferredName, SIN: rows?.sin, Gender: rows?.gender, DOB: dateOfBirth, Address: rows?.address, City: rows?.city, Province: rows?.province, PostalCode: rows?.postalCode, EmergencyContact: rows?.emergencyContact, MedicalCondition: rows?.medicalCondition,
                    StartDate: startDate, IsLegal: rows?.legalToWork ? rows?.legalToWork : false, EmergencyContactPhone: rows?.emergencyContactPhone, HideInCalendar: rows?.hideInCalendar ? rows?.hideInCalendar : false
                }}
                validate={values => {

                    const errors = {};
                    if (!values.FirstName) {
                        errors.FirstName = 'Required!';
                    }
                    if (!values.LastName) {
                        errors.LastName = 'Required!';
                    }

                    if (!values.UserEmail) {
                        errors.UserEmail = 'Required!';
                    }

                    if (!values.UserType) {
                        errors.UserType = 'Required!';
                    }

                    return errors;
                }}

                const handleMouseDownPwd={(event) => {
                    event.preventDefault();
                }}
                onSubmit={(values, { setSubmitting }) => {
 
                    setLoading(true);
                    setTimeout(() => {
                        const postData = {
                            userId: id,
                            userEmail: values.UserEmail,
                            firstName: values.FirstName,
                            lastName: values.LastName,
                            preferredName: values.PreferredName,
                            sin: values.SIN,
                            gender: values.Gender,
                            dateOfBirth: (values.DOB == "" ? null : values.DOB),
                            address: values.Address,
                            city: values.City,
                            province: values.Province,
                            postalCode: values.PostalCode,
                            phone: values.Phone,
                            userType: values.UserType,
                            isActive: values.IsActive,
                            emergencyContact: values.EmergencyContact,
                            userRoles: roleArray,
                            rolesUpdated:  (userProfile?.userType=="Admin" ? rolesUpdated : false),
                            canAddHours: userAccess,
                            medicalCondition: values.MedicalCondition,
                            startDate: (values.StartDate == "" ? null : values.StartDate),
                            legalToWork: values.IsLegal,
                            EmergencyContactPhone: values.EmergencyContactPhone,
                            hideInCalendar: values.HideInCalendar
                        }
                        const RAFTCO_URL = config.API_URL + "/api/Users/update"
                        axios.post(RAFTCO_URL, postData, {
                            headers: {
                                'Authorization': `Basic ${tokens.jwtToken}`
                            }
                        })
                            .then((res) => {

                                if (res.data.successCode === 1) {
                                    setTimeout(() => { userUpdate() }, 2000)
                                } else {
                                    setTimeout(() => { userUpdateError() }, 2000)
                                }
                                if (userProfile?.userType == "Admin") {
                                    setTimeout(() => { navigate("/user/list") }, 5000)
                                } else {
                                    setTimeout(() => { navigate("/trip/list") }, 5000)
                                }

                            }, 400).catch(err => {
                                if (err.response.status == 401) {
                                    navigate("/login")
                                }
                            }).finally(() => {
                                setLoading(false);
                            })
                        setSubmitting(false)
                    })
                }}
            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <UserWrapper>
                            {message}
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <PageTitle>Staff</PageTitle>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} className="text-right">

                                </Grid>
                            </Grid>

                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="username">First Name</InputLabel>
                                        <Input
                                            id="firstname"
                                            name="FirstName"
                                            type="text"
                                            value={values.FirstName || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="lastname">Last Name</InputLabel>
                                        <Input
                                            id="lastname"
                                            name="LastName"
                                            type="text"
                                            value={values.LastName || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="preferredname">Preferred Name</InputLabel>
                                        <Input
                                            id="preferredname"
                                            name="PreferredName"
                                            type="text"
                                            value={values.PreferredName || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        <span className="errMsg">{errors.PreferredName && touched.PreferredName && errors.PreferredName}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="sin">Social Insurance Number</InputLabel>
                                        <Input
                                            id="sin"
                                            name="SIN"
                                            type="text"
                                            value={values.SIN || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        <span className="errMsg">{errors.SIN && touched.SIN && errors.SIN}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="usertype">Gender</InputLabel>
                                        <Select
                                            id="gender"
                                            name="Gender"
                                            label="Gender"
                                            variant="standard"
                                            value={values.Gender || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ><MenuItem value="Male">Male</MenuItem>
                                            <MenuItem value="Female">Female</MenuItem>
                                            <MenuItem value="Other">Other</MenuItem>
                                        </Select>
                                        <span className="errMsg">{errors.Gender && touched.Gender && errors.Gender}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            id="Date"
                                            label="Date of BIrth"
                                            name="DOB"
                                            type="date"
                                            color="primary"
                                            value={values.DOB || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />
                                        <span className="errMsg">{errors.DOB && touched.DOB && errors.DOB}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="address">Address</InputLabel>
                                        <Input
                                            id="address"
                                            type="text"
                                            name="Address"
                                            value={values.Address || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.Address && touched.Address && errors.Address}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="city">City</InputLabel>
                                        <Input
                                            id="city"
                                            type="text"
                                            name="City"
                                            value={values.City || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.City && touched.City && errors.City}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="province">Province</InputLabel>
                                        <Input
                                            id="province"
                                            type="text"
                                            name="Province"
                                            value={values.Province || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.Province && touched.Province && errors.Province}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="postalcode">Postal Code</InputLabel>
                                        <Input
                                            id="postalcode"
                                            type="text"
                                            name="PostalCode"
                                            value={values.PostalCode || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.PostalCode && touched.PostalCode && errors.PostalCode}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="phone">Phone</InputLabel>
                                        <Input
                                            id="phone"
                                            type="phone"
                                            name="Phone"
                                            value={values.Phone || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.Phone && touched.Phone && errors.Phone}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="useremail">User Email</InputLabel>
                                        <Input
                                            id="useremail"
                                            type="email"
                                            name="UserEmail"
                                            value={values.UserEmail || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            disabled={true}
                                        />
                                        <span className="errMsg">{errors.UserEmail && touched.UserEmail && errors.UserEmail}</span>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={4}>
                                {(userProfile?.userType === "Admin" || userProfile?.userType === "Leader") && (<Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="usertype">User Type</InputLabel>
                                        <Select
                                            labelId="usertype"
                                            id="usertype"
                                            label="User Type"
                                            variant="standard"
                                            name="UserType"
                                            value={values.UserType || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            inputProps={{
                                                readOnly: userProfile?.userType === "Leader"
                                            }}
                                        ><MenuItem value="Admin">Admin</MenuItem>
                                            <MenuItem value="User">User</MenuItem>
                                            <MenuItem value="Leader">Leader</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>)}
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="emergencycontact">Emergency Contact</InputLabel>
                                        <Input
                                            id="emergencycontact"
                                            type="text"
                                            name="EmergencyContact"
                                            value={values.EmergencyContact || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.EmergencyContact && touched.EmergencyContact && errors.EmergencyContact}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="emergencycontactphone">Emergency Contact Phone</InputLabel>
                                        <Input
                                            id="emergencycontactphone"
                                            type="text"
                                            name="EmergencyContactPhone"
                                            value={values.EmergencyContactPhone || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.EmergencyContactPhone && touched.EmergencyContactPhone && errors.EmergencyContactPhone}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <InputLabel htmlFor="medicalcondition">Medical Condition</InputLabel>
                                        <Input
                                            id="medicalcondition"
                                            type="text"
                                            name="MedicalCondition"
                                            value={values.MedicalCondition || ''}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <span className="errMsg">{errors.MedicalCondition && touched.MedicalCondition && errors.MedicalCondition}</span>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            id="Date"
                                            label="Start Date"
                                            name="StartDate"
                                            type="date"
                                            color="primary"
                                            value={values.StartDate || ''}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />
                                        <span className="errMsg">{errors.StartDate && touched.StartDate && errors.StartDate}</span>
                                    </FormControl>
                                </Grid>
                                {userProfile?.userType == "Admin" && <Grid item xs={12} sm={4} md={4}>
                                    <FormControlLabel
                                        label="Active"
                                        control={<Switch
                                            id="isactive"
                                            checked={values.IsActive}
                                            name="IsActive"
                                            color="warning"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={handleChange}
                                        />} >
                                    </FormControlLabel>
                                </Grid>}
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControlLabel
                                        label="Are you legally able to work in Canada?"
                                        control={<Switch
                                            id="islegal"
                                            checked={values.IsLegal}
                                            name="IsLegal"
                                            color="warning"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={handleChange}
                                        />} >
                                    </FormControlLabel>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControlLabel
                                        label="Don't show in calendar"
                                        control={<Switch
                                            id="hideInCalendar"
                                            checked={values.HideInCalendar}
                                            name="HideInCalendar"
                                            color="warning"
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                            onChange={handleChange}
                                        />} >
                                    </FormControlLabel>
                                </Grid>
                            </Grid>

                            <br></br>
                            {userProfile?.userType == "Admin" && <Grid container spacing={4}>
                                <Grid item xs={12} sm={4} md={4}>
                                    <FormControlLabel control={
                                        <Checkbox
                                            color="warning"
                                            checked={userAccess}
                                            onChange={(e) => handleUserAccess(e)}
                                        />} label="Can Add Time Entry" />
                                </Grid>
                            </Grid>}
                            <br></br>
                            <br></br>
                            {userProfile?.userType == "Admin" && <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12} className="mx-4" style={{ border: "2px solid lightgrey", borderRadius: "10px" }}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                                <TextField
                                                    label="Role"
                                                    select
                                                    value={role}
                                                    onChange={e => handleRole(e)}
                                                    name="role"
                                                    variant="standard" >
                                                    {rolesList.map(option => (
                                                        <MenuItem key={option.roleId} value={option}>
                                                            {option.roleName}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <FormControl style={{ width: "100%" }} className={clsx(classes.withoutLabel, classes.textField)}>
                                                <InputLabel htmlFor="lastname">Rate in dollars</InputLabel>
                                                <Input
                                                    id="rate"
                                                    name="rate"
                                                    type="text"
                                                    value={rate}
                                                    onBlur={handleBlur}
                                                    onChange={e => setRate(e.target.value)}
                                                />
                                            </FormControl>
                                            <FontAwesomeIcon icon={faAdd} className="closeIcon m-3"
                                                style={{
                                                    borderRadius: "50%",
                                                    backgroundColor: "rgb(245, 147, 0)",
                                                    color: "white",
                                                    padding: "5px",
                                                    fontSize: "20px",
                                                    cursor: "pointer"
                                                }} onClick={() => addRoles()} />
                                        </Grid>
                                    </Grid>
                                    {displayRoles()}
                                    <br></br>
                                </Grid>
                            </Grid>}
                            <Grid container spacing={4} className="mt-3">
                                <Grid item xs={12} sm={12} md={12} className="text-right">
                                    <Button type="button" variant="outlined" color="primary" className="noShadow cancelBtn" onClick={() => handleCancel()}>
                                        Cancel
                                    </Button>&nbsp;&nbsp;
                                    <Button type="submit" variant="contained" color="primary" className="noShadow addBtn" disabled={isSubmitting} >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>

                        </UserWrapper>
                    </form>
                )}
            </Formik>
        </div>
    );
}
