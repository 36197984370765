import React, { useEffect } from 'react';
import config from '../../config.json';
import axios from 'axios';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import styled from 'styled-components';
import { Button, TextField, Autocomplete } from '@mui/material';
import { Loader } from '../../../shared/components/Loader';
import { FilterInput } from '../../../shared/components/FilterInput';
import Moment from 'moment';
import GetAppIcon from '@mui/icons-material/GetApp';
import { useNavigate } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'


const TableWrapper = styled.div`
.css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: #F59300 !important;
}
    .materialTableStyle {
        table {
            border-collapse: separate;
            border-spacing: 0 1em;
            width: 99%;
            margin: auto;
        }
        th:first-child, td:first-child {
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
        }
        th:last-child, td:last-child {
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
        }
        th {
            font-size: 12px !important;
            white-space: nowrap;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            color: #F59300;
            height: 55px;
        }
        .MuiTableSortLabel-active {
            color: #F59300;
        }
        .MuiTableSortLabel-root:hover {
            color: #F59300;
        }
        .MuiTableSortLabel-icon {
            color: #F59300 !important;
        }
        td {
            position: relative;
            font-size: 12px !important;
            color: #000;
            padding-left: 8px;
            padding-right: 8px;
            padding-top: 10px;
            padding-bottom: 10px;
            background-color: #fff;
            border: none;
            height: 60px;
        }
        tbody {
            tr {
                padding : 10px;
                position: relative;
                border-radius: 15px;
                transition: all 0.3s linear;
              
                &:hover {
                    box-shadow: 0 10px 6px rgba(0,0,0,0.10), 0 6px 3px rgba(0,0,0,0.10);

                    .actionListDeleteIconPosition {
                        display: block;
                    }
                    .actionListEditIconPosition {
                        display: block;
                    }
                    .actionListInfoIconPosition {
                        display: block;
                    }
                }
            }
        }
        .actionListDeleteIconPosition {
            position: absolute;
            top: -16px;
            right: 15px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListEditIconPosition {
            position: absolute;
            top: -16px;
            right: 56px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionListInfoIconPosition {
            position: absolute;
            top: -16px;
            right: 97px;
            display: none;
            transition: all 0.3s linear;
        }
        .actionBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);

            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionCSVBtn {
            padding: 8px;
            background-color: #ffffff;
            box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
            color: #ffffff;
            &:hover {
                background-color: rgba(245,147,0,0.1);
                color: #F59300;
            }
        }
        .actionIcon {
            font-size: 17px;
        }
    }
    .filterRowAdmin {
        display: grid;
        grid-template-columns: 14fr 14fr 4fr 4fr 2fr 2fr 1fr;
        background-color: rgba(245, 147, 0, 0.1);
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding: 15px 10px;
        width: 99%;
        margin: auto;
    }
    .filterRow {
        display: grid;
        grid-template-columns: 5fr 5fr 3fr 3fr 1fr;
        background-color: rgba(245, 147, 0, 0.1);
        border-radius: 15px;
        align-items: center;
        grid-column-gap: 10px;
        padding: 15px 10px;
        width: 99%;
        margin: auto;
    }
    .filterIconSize{
        font-size: 18px;
    }
    .filterDropdown {
        .MuiFilledInput-root {
            background-color: #ffffff;
            border-radius: 0;
            
        }
        .MuiFilledInput-input {
            padding: 5px 10px 5px;
            font-size: 12px;
        }
        .MuiFilledInput-underline:before {
            border: 0;
        }
        .MuiInputLabel-filled {
            transform: translate(12px,7px) scale(1);
            font-size: 12px;
        }
        .MuiFilledInput-underline:after {
            border: 0;
        }
        .MuiSelect-select:focus {
            background-color: #ffffff;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
            transform: translate(0px,-12px) scale(0.90) !important;
            color: #F59300;
        }
    }
    .filterAutocomplete{
        .dialogStyle {
        height : 200px ;
        width : 1000px ;
    }
    .closeIcon { 
        position: absolute;
        left: 450px;
        right: 0px;
        top: 20px;
        bottom: 0px;
    }
    .MuiFilledInput-root {
        background-color: #ffffff;
        border-radius: 0;
        height : 30px;
        padding: 0px !important;
    }
    .MuiFilledInput-input {
        padding: 5px 0px 5px 5px;
        font-size: 12px;
    }
    .MuiFilledInput-underline:before {
        border: 0;
    }
    .MuiInputLabel-filled {
        transform: translate(12px,7px) scale(1);
        font-size: 12px;
    }
    .MuiFilledInput-underline:after {
        border: 0;
    }
    .MuiSelect-select:focus {
        background-color: #ffffff;
    }
    .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(0px,-12px) scale(0.90) !important;
        color: #F59300;
    }
    .css-1gctnaj-MuiInputBase-input-MuiFilledInput-input {
        height : 0.2px !important;
    }
   
}
.css-1qqsdnr-MuiAutocomplete-root .MuiFilledInput-root {
    padding-top : 0px !important;
}

.div-only-mobile {
    display: grid;
    grid-template-columns: 5fr 5fr 3fr ;
    align-items: center;
    grid-column-gap: 10px;
    width: 99%;
}

@media only screen and (max-width: 600px) {
    .filterRow , .filterRowAdmin{
        grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)) !important;
        row-gap : 10px;
    }
    .div-only-mobile{
        visibility:visible;
    }
    .actionListDeleteIconPosition {
        display: block !important;
    }
    .actionListEditIconPosition {
        display: block !important;
    }
    .actionListInfoIconPosition {
        display: block !important;
    }
    .materialTableStyle td {
        font-size: 16px !important;
    }
    
  }
  @media screen and (max-width: 40em)
  .responsiveTable tbody tr {
      border-spacing: 10px !important;
      padding: 0.25em !important;
  }
`;




const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));

export default function TripDetail() {
    const classes = useStyles();
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [message, setMessage] = React.useState(" ");
    const [loading, setLoading] = React.useState(false);
    var [rows, setRows] = React.useState([]);
    var [filteredRows, setFilteredRows] = React.useState([]);
    var [disable, setDisable] = React.useState(true);
    var [startDate, setStartDate] = React.useState("");
    var [endDate, setEndDate] = React.useState("");
    var [startDateRange, setStartDateRange] = React.useState();
    var [endDateRange, setEndDateRange] = React.useState();
    var [userList, setUserList] = React.useState([]);
    var [leaderList, setLeaderList] = React.useState([]);
    var [tripLeaderId, setTripLeaderId] = React.useState(0);
    var [tripUserId, setTripUserId] = React.useState(0);
    const [pdfMessage, setPdfMessage] = React.useState("");
    let tokens = localStorage.getItem("loginRes");
    tokens = JSON.parse(tokens)
    const navigate = useNavigate();
    var [selectedLeader, setSelectedLeader] = React.useState({});
    var [selectedUser, setSelectedUser] = React.useState({});

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }


    let headCells = [
        { id: 'tripDate', numeric: false, label: 'Trip Date' },
        { id: 'tripName', numeric: false, label: 'Trip Name' },
        { id: 'tripUser', numeric: false, label: 'Staff Name' },
        { id: 'roleName', numeric: false, label: 'Role' },
        { id: 'hours', numeric: false, label: 'Hours' },
        { id: 'tripRate', numeric: false, label: 'Earnings' }
    ];

    function EnhancedTableHead(props) {
        const { classes, order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };
        if (tokens.userType == "Leader") {
            headCells = headCells.filter(h => h.id != "tripRate")
        }

        return (
            <Thead>
                <Tr>
                    {headCells.map((headCell) => (
                        <Th
                            key={headCell.id}
                            align={headCell.numeric ? 'center' : 'left'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </Th>
                    ))}
                </Tr>
            </Thead>
        );
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
    };

    useEffect(() => {
        tripList()
    }, [])

    const getBatchDate = async () => {

        const RAFTCO_DATERANGE_URL = config.API_URL + "/Dashboard/api/daterange"
        let res = await axios.get(RAFTCO_DATERANGE_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
        if (res.data.successCode === 1) {
            startDateRange = res.data.data[0]
            setStartDateRange(startDateRange)
            endDateRange = res.data.data[1]
            setEndDateRange(endDateRange)
            var firstDateArray = res.data.data[0].split("T")
            startDate = firstDateArray[0]
            setStartDate(startDate)
            var lastDateArray = res.data.data[1].split("T")
            endDate = lastDateArray[0]
            setEndDate(endDate)

        } else {
            setMessage(
                <div className="message">
                    <div className="alert alert-danger" role="alert">
                        "An error occured,try again later"
                    </div>
                </div>)
            setTimeout(() => { setMessage("") }, 5000)
        }

    }

    const getTripList = () => {
        setLoading(true);
        setTimeout(() => {
        const RAFTCO_TRIP_URL = config.API_URL + "/Trip/api/Trip/detail"
        if (tokens.userType == "Leader") {
            tripLeaderId = tokens.id
            setTripLeaderId(tripLeaderId)
        }
        if (tokens.userType == "User") {
            tripUserId = tokens.id
            setTripUserId(tripUserId)
        }
        const data = {
            fromDate: startDate,
            toDate: endDate,
            tripLeaderId: tripLeaderId,
            tripUserId: tripUserId
        }
        axios.post(RAFTCO_TRIP_URL, data, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(rows);
                    // if (tokens.userType == "User" || tokens.userType == "Leader") {
                    //     filteredRows = rows.filter(r => parseInt(r.createdBy) == tokens.id);
                    //     setFilteredRows(filteredRows)
                    // } else {
                    //     filteredRows = rows;
                    //     setFilteredRows(filteredRows)
                    // }
                    setDisable(false)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
            .finally(() => {
                setLoading(false);
            })
        })
    }

    const getAwaitedTripList = async () => {
        const result = await getBatchDate()
        getTripList()
    }


    const tripList = () => {
        const RAFTCO_USER_URL = config.API_URL + "/api/Users"
        axios.get(RAFTCO_USER_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    let users = res.data.data
                    userList = res.data.data
                    // userList = users.filter(u => u.userType == "User")
                    setUserList(userList);
                    leaderList = users.filter(u => u.userType == "Leader")
                    setLeaderList(leaderList)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })

        var batchDate = sessionStorage.getItem("d_dateRange")
        if (batchDate) {

            batchDate = JSON.parse(batchDate)
            startDate = batchDate.d_startDate
            setStartDate(startDate)
            endDate = batchDate.d_endDate
            setEndDate(endDate)
            tripUserId = batchDate.d_userId
            setTripUserId(tripUserId)
            tripLeaderId = batchDate.d_leaderId
            setTripLeaderId(tripLeaderId)
            selectedLeader = batchDate.d_selectedLeader
            setSelectedLeader(selectedLeader)
            selectedUser = batchDate.d_selectedUser
            setSelectedUser(selectedUser)
            getTripList()
        } else {
            getAwaitedTripList()
        }


    }

    const UpdateTrip = (tripId, userId) => {
        navigate("/trip/edit/" + tripId)
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


    const searchTrip = () => {

        var dateRange = {
            "d_startDate": startDate,
            "d_endDate": endDate,
            "d_userId": tripUserId,
            "d_leaderId": tripLeaderId,
            "d_selectedUser": selectedUser,
            "d_selectedLeader": selectedLeader
        }
        dateRange = JSON.stringify(dateRange)
        sessionStorage.setItem("d_dateRange", dateRange);

        const RAFTCO_TRIP_URL = config.API_URL + "/Trip/api/Trip/detail"
        const data = {
            fromDate: startDate,
            toDate: endDate,
            tripLeaderId: tripLeaderId,
            tripUserId: tripUserId
        }
        axios.post(RAFTCO_TRIP_URL, data, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    rows = res.data.data
                    setRows(rows);
                    filteredRows = rows;
                    setFilteredRows(rows);
                    // if (tokens.userType == "User" || tokens.userType == "Leader") {
                    //     filteredRows = rows.filter(r => parseInt(r.createdBy) == tokens.id);
                    //     setFilteredRows(filteredRows)
                    // } else {
                    //     filteredRows = rows;
                    //     setFilteredRows(filteredRows)
                    // }
                    setDisable(false)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }

    const deleteTrip = (filteredRows) => {

        const RAFTCO_DELETE_URL = config.API_URL + "/Trip/api/Trip/delete/" + filteredRows.tripId
        axios.delete(RAFTCO_DELETE_URL, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    tripList()
                    setMessage(
                        <div className="message">
                            <div className="alert alert-success" role="alert">
                                "Trip deleted successfully"
                            </div>
                        </div>)
                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                }
                setTimeout(() => { setMessage("") }, 3000)
                //  navigate("/trip/list")
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }

    const downloadcsv = () => {

        var postData = {
            fromDate: startDate,
            toDate: endDate,
            tripLeaderId: tripLeaderId,
            tripUserId: tripUserId,
            userType: tokens.userType
        }
        const RAFTCO_PDF_URL = config.API_URL + "/Trip/api/Trip/detailexport"

        axios.post(RAFTCO_PDF_URL, postData, {
            headers: {
                'Authorization': `Basic ${tokens.jwtToken}`
            }
        })
            .then((res) => {
                if (res.data.successCode === 1) {
                    const data = res.data.data;
                    const element = document.createElement("a");
                    const file = new Blob([data], { type: 'text/plain' });
                    element.href = URL.createObjectURL(file);
                    element.download = 'userdetail.csv';
                    document.body.appendChild(element);
                    element.click();
                } else {
                    setPdfMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setPdfMessage("") }, 5000)
                }
            }, 400).catch(err => {
                if (err.response.status == 401) {
                    navigate("/login")
                }
            })
    }

    const handleAddTrip = () => {
        if (!(tokens.userType == "User" && tokens.canAddHours == false))
            return (
                <div>
                    <Button variant="contained" color="primary" className="noShadow btn-default addBtn " onClick={() => navigate("/trip/add")}>
                        Add
                    </Button>
                </div>
            )
    }

    if (loading) {
        return (
            <div className="d-flex align-items-center vh-100 justify-content-center text-center font-weight-bold font-size-lg py-3">
                <Loader color="#ff9900" />
            </div>
        );
    }


    return (

        <TableWrapper>
            {message}
            <div className={`${classes.root} materialTableStyle`}>
                <div className={tokens.userType == "Admin" ? "filterRowAdmin" : "filterRow"}>
                    {tokens.userType == "Admin" ? <Autocomplete
                        debug="true"
                        options={leaderList}
                        className="filterAutocomplete"
                        getOptionLabel={(option) => option.fullName || ''}
                        value={selectedLeader}
                        onChange={(event, user) => { setTripLeaderId(user?.userId); setSelectedLeader(user) }}
                        renderInput={(params) => <TextField  {...params} fullWidth label="Select Leader" className="filterAutocomplete" variant="filled" />
                        }
                    /> : ""}
                    {tokens.userType == "Admin" ? <Autocomplete
                        debug="true"
                        options={userList}
                        className="filterAutocomplete"
                        getOptionLabel={(option) => option.fullName || ''}
                        value={selectedUser}
                        onChange={(event, user) => { setTripUserId(user?.userId); setSelectedUser(user) }}
                        renderInput={(params) => <TextField  {...params} fullWidth label="Select User" className="filterAutocomplete" variant="filled" />
                        }
                    /> : ""}
                    <div>
                        <FilterInput placeholder="Start Date(yyyy-mm-dd)" type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
                    </div>
                    <div>
                        <FilterInput placeholder="End Date(yyyy-mm-dd)" type="date" value={endDate} onChange={(event) => setEndDate(event.target.value)} />
                    </div>
                    <div className='div-only-mobile'>
                        <div>
                            <Button type="submit" variant="contained" color="primary" className="noShadow w-100 addBtn" onClick={() => searchTrip()} >
                                Search
                            </Button>
                        </div>
                        {handleAddTrip()}
                        <div>
                            {tokens?.userType?.toLowerCase() == "admin" && <Tooltip title="Download CSV" aria-label="Download">
                                <IconButton className="actionCSVBtn csvDownloadIcon" aria-label="Download" onClick={() => { downloadcsv() }}>
                                    <GetAppIcon className="actionIcon" />
                                </IconButton>
                            </Tooltip>}
                        </div>
                    </div>
                </div>
                <Table
                >
                    <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                    />

                    <Tbody>
                        {stableSort(filteredRows, getComparator(order, orderBy))
                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((filteredRows, index) => {
                                if (filteredRows.tripDate) {
                                    filteredRows.tripDate = Moment(Date.parse(filteredRows.tripDate)).format('MM/DD/YYYY')
                                }
                                return (

                                    <Tr
                                        key={filteredRows.tripId + filteredRows.roleName}>
                                        <Td align="left">{filteredRows.tripDate}</Td>
                                        <Td>{filteredRows.tripName}</Td>
                                        <Td>{filteredRows.tripUser}</Td>
                                        <Td>{filteredRows.roleName}</Td>
                                        <Td>{filteredRows.hours}</Td>
                                        {tokens.userType != "Leader" && <Td>{'\u0024' + filteredRows.tripRate}</Td>}
                                    </Tr>
                                );
                            })}
                    </Tbody>
                </Table>
            </div>
        </TableWrapper>
    );
}


