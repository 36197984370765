import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config.json';
import { useParams } from "react-router-dom";
import { Card, CardContent, Typography, FormControl, TextField, Button, Box } from '@mui/material';
import styled from 'styled-components';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    margin: {
        margin: theme.spacing(0),
    },
    withoutLabel: {
        marginTop: theme.spacing(0),
    }
}));

const FormWrapper = styled.div`
.alignCard {
    display: flex;
    justify-content : center;
    margin-top : 10%;
}
`;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function ForgotPassword() {
    const classes = useStyles();
    const [message, setMessage] = useState("")
    const { pwdkey } = useParams();
    const [valid, setValid] = useState();
    const [email, setEmail] = useState("");

    useEffect(() => {

        const Raftco_URL = config.API_URL + "/api/Users/checkkeyvalid/" + pwdkey
        axios.get(Raftco_URL)
            .then((res) => {

                if (res.data.successCode === 0) {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                An error occured ,try again later.
                            </div>
                        </div>)
                }
                else {
                    if (res.data.data == 'VALID') {
                        setValid('VALID')
                    }
                    else if (res.data.data == 'INVALID') {
                        setValid('INVALID')
                    }
                    else if (res.data.data == 'EXPIRED') {
                        setValid('EXPIRED')
                    }
                }

            }, 400).catch(err => {
                console.log(err)
            })
    })

    const resetForm = (values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting) => {
        if (valid == 'VALID') {
            return (
                <form onSubmit={handleSubmit}>
                    <FormWrapper>
                        <div className="alignCard">
                            <Card style={{ width: "350px", height: "350px", background: "#F8F8F8", borderRadius: "10px" }} className="text-center">
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div" >
                                        Reset Password
                                    </Typography>
                                    <br></br>
                                    <FormControl>
                                        <TextField
                                            fullWidth
                                            label="Password"
                                            id="password"
                                            name="Pwd"
                                            type="password"
                                            value={values.Pwd}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            variant="outlined"
                                            sx={{ width: 250 }}
                                            InputProps={{ sx: { height: 45 } }}
                                        />
                                        <span className="errMsg">{errors.FirstName && touched.FirstName && errors.FirstName}</span>
                                    </FormControl>
                                    <br></br>
                                    <br></br>
                                    <FormControl className={clsx(classes.withoutLabel, classes.textField)}>
                                        <TextField
                                            fullWidth
                                            label="Confirm Password"
                                            id="confirmpwd"
                                            name="ConformPwd"
                                            type="password"
                                            value={values.ConformPwd}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            sx={{ width: 250 }}
                                            InputProps={{ sx: { height: 45 } }}
                                        />
                                        <span className="errMsg">{errors.FirstName && touched.FirstName && errors.FirstName}</span>
                                    </FormControl>
                                    <div className='mt-3'>
                                        <Button type="submit" className="submitBtn mt-3" variant="outlined" disabled={isSubmitting}>Submit</Button>
                                    </div>
                                    <br></br>
                                    {message}
                                    <br></br>
                                </CardContent>
                            </Card>
                        </div>
                    </FormWrapper>
                </form>
            )
        } else {
            return (
                <FormWrapper>
                    <div className="alignCard">
                        <Card style={{ width: "350px", height: "320px", background: "#F8F8F8", borderRadius: "10px" }} className="text-center">
                            <CardContent>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    {valid == "EXPIRED" ? 'Sorry, your token expired!' : 'Sorry, your token is invalid!'}
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    We'll need to re-send your authentication email
                                </Typography>
                                <TextField fullWidth id="outlined-basic" label="email" variant="standard" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <div>
                                    <Button type="submit" className="submitBtn mt-4" variant="outlined" onClick={() => sendEmail()}>Submit</Button>
                                    <br></br>
                                    <br></br>
                                    {message}
                                    <br></br>
                                </div>

                            </CardContent>
                        </Card>
                    </div>
                </FormWrapper>
            )
        }
    }

    const sendEmail = () => {
        const RAFTCO_EMAIL_URL = config.API_URL + "/api/Users/pwdresetemail/" + email
        axios.get(RAFTCO_EMAIL_URL)
            .then((res) => {
                if (res.data.successCode === 1) {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-success" role="alert">
                                "Email sent successfully!"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)

                } else {
                    setMessage(
                        <div className="message">
                            <div className="alert alert-danger" role="alert">
                                "An error occured,try again later"
                            </div>
                        </div>)
                    setTimeout(() => { setMessage("") }, 5000)
                }

            })
    }

    return (
        <Formik
            initialValues={{ Pwd: '', ConformPwd: '' }}
            validate={values => {
                const errors = {};

                if (!values.Pwd) {
                    errors.Pwd = 'Required!';
                }
                return errors;
            }}


            onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                    const data = {
                        pwdKey: pwdkey,
                        password: values.Pwd
                    }

                    const Raftco_URL = config.API_URL + "/api/Users/pwdreset"
                    axios.post(Raftco_URL, data)
                        .then((res) => {

                            if (res.data.successCode === 0) {
                                setMessage(
                                    <div className="message">
                                        <div className="alert alert-danger" role="alert">
                                            An error occured ,try again later!
                                        </div>
                                    </div>)
                            }
                            else {

                                setMessage(
                                    <div className="message">
                                        <div className="alert alert-success" role="alert">
                                            "Password reset successfull!"
                                        </div>
                                    </div>)
                                setTimeout(() => { setMessage("") }, 5000)
                            }

                        }, 400).catch(err => {
                            console.log(err)
                        })
                })

            }}

        >

            {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,

            }) => (
                <div>
                    {resetForm(
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting)}
                </div>
            )
            }
        </Formik >
    )
}